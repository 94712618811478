import React, { useRef, useState } from "react";
import { AuthWrapper, AwsConfiguration, Credential, CredentialDeliveryMethod, OrderApiClient } from "@sade/data-access";
import { Button, Dialog, InputLabel, TextField, Typography } from "@mui/material";
import { DialogTitle } from "../../../../ui/dialog-title";
import { translations } from "../../../../../generated/translationHelper";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Loader from "../../../../ui/loader";
import { useNotification } from "../../../../ui/notification";

interface Props {
  orderId: number;
  credential: Credential;
  close: () => void;
  closeAndRefresh: (credential: Credential) => Promise<void>;
}

export const CredentialAssignmentDialog: React.FC<Props> = (props: Props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const hasBeenSubmittedOnce = useRef(false);

  const [isLoading, setIsLoading] = useState(false);
  const [assignee, setAssignee] = useState("");
  const [assigneeValidationErrorMessage, setAssigneeValidationErrorMessage] = useState<string | undefined>(undefined);

  const displayNotification = useNotification();

  const isReassignment = props.credential.assignedTo !== undefined;

  function resolveDeliveryMethod(assignee: string): CredentialDeliveryMethod | undefined {
    const emailPattern = /^.+@.+$/; // at least one @ with something both sides of it
    if (emailPattern.test(assignee)) {
      return CredentialDeliveryMethod.Email;
    } else if (assignee.includes("+") && assignee.length > 3 && assignee.length < 27) {
      return CredentialDeliveryMethod.SMS;
    } else {
      return undefined;
    }
  }
  async function assignTo(assignee: string): Promise<void> {
    const deliveryMethod = resolveDeliveryMethod(assignee);
    const response = await apiClient.current.assignCredential({
      orderId: props.orderId,
      credentialId: props.credential.credentialId,
      assignTo: assignee,
      deliveryMethod: deliveryMethod!,
    });
    props.credential.status = response.newCredentialStatus;
    props.credential.assignedTo = response.newAssignee;
  }
  function validateAssignee(assignee: string): boolean {
    if (resolveDeliveryMethod(assignee) === undefined) {
      setAssigneeValidationErrorMessage(translations.orders.texts.assigneeIsInvalid({ invalidAssignee: assignee }));
      return false;
    } else if (assignee === props.credential.assignedTo) {
      setAssigneeValidationErrorMessage(translations.orders.texts.credentialAlreadyAssigned({ assignee: assignee }));
      return false;
    } else {
      setAssigneeValidationErrorMessage(undefined);
      return true;
    }
  }
  async function validateAndAssign(): Promise<void> {
    try {
      setIsLoading(true);

      hasBeenSubmittedOnce.current = true;
      if (validateAssignee(assignee)) {
        await assignTo(assignee);
        displayNotification({
          title: translations.orders.texts.credentialAssigned(),
          message: translations.orders.texts.deliveredCredentialTo({
            registrationKey: props.credential.registrationKey,
            recipient: assignee,
          }),
          variant: "success",
        });
        props.close();
      }
    } catch (e) {
      console.error(e);
      displayNotification({
        title: translations.orders.texts.credentialAssignmentFailed(),
        message: translations.orders.texts.failedToDeliverCredential({
          registrationKey: props.credential.registrationKey,
          recipient: assignee,
        }),
        variant: "error",
      });

      // a failed call to the backend might still have changed the credential state before the failure, so we fetch up-to-date credential
      await props.closeAndRefresh(props.credential);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={true} onClose={props.close} maxWidth="sm">
      <DialogTitle onClose={props.close}>
        {isReassignment
          ? translations.orders.texts.reassignCredentialToEndUser()
          : translations.orders.texts.assignCredentialToEndUser()}
      </DialogTitle>
      <MuiDialogContent>
        <Typography marginBottom="16px">
          {isReassignment
            ? translations.orders.texts.deliverCredentialToSamePerson({
                previousAssignee: props.credential.assignedTo!,
              })
            : translations.orders.texts.deliverCredentialViaEmailOrSms()}
        </Typography>
        <InputLabel htmlFor="newAssignee">{translations.orders.inputs.phoneNumberOrEmail()}</InputLabel>
        <TextField
          id="newAssignee"
          onChange={(e): void => {
            const newValue = e.target.value;
            setAssignee(newValue);
            if (hasBeenSubmittedOnce.current) {
              validateAssignee(newValue);
            }
          }}
          error={assigneeValidationErrorMessage !== undefined}
          helperText={assigneeValidationErrorMessage ?? " "}
          size="small"
          fullWidth
        />
      </MuiDialogContent>
      <MuiDialogActions>
        <Button variant="outlined" color="primary" onClick={props.close}>
          {translations.common.buttons.cancel()}
        </Button>
        <Button variant="contained" color="primary" onClick={validateAndAssign} disabled={isLoading}>
          <Loader
            size={1}
            topBottomPadding={"0"}
            leftRightPadding={"0"}
            hidden={!isLoading}
            styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          />
          <span style={{ visibility: isLoading ? "hidden" : "visible" }}>
            {isReassignment
              ? translations.orders.buttons.reassignAndDeliver()
              : translations.orders.buttons.assignAndDeliver()}
          </span>
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};
