import { Button, TableCell, TableRow, Typography } from "@mui/material";
import React, { Component, Fragment, ReactNode } from "react";
import DropdownSelection from "../../../ui/dropdown-selection";
import { UserOrganizationAction } from "./user-organization-action";
import { Maybe, Role, User, UserObserver } from "@sade/data-access";
import Loader from "../../../ui/loader";
import ErrorDialog from "../../../ui/error-dialog";
import { translations } from "../../../../generated/translationHelper";
import { translateRoleName } from "./translate-role-name";
import { AdminCellItem } from "../util-components/admin-cell-item";

interface Props {
  actionCallback: (action: UserOrganizationAction, user: User) => void;
  availableRoles: Role[];
  allRoles: Role[];
  enableRoleSelection: boolean;
  organizationId: string;
  user: User;
  userAction: UserOrganizationAction;
  ["data-testid"]: string;
}

interface State {
  loading: boolean;
  isCurrentUser: boolean;
  selectedRole?: Role;
  cannotEdit?: boolean;
  error?: string;
}

export default class UserListItem extends Component<Props, State> implements UserObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isCurrentUser: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    this.props.user.addObserver(this);
    await this.resolveCurrentRole();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.organizationId !== this.props.organizationId) {
      await this.resolveCurrentRole();
    }
  }

  public componentWillUnmount(): void {
    this.props.user.removeObserver(this);
  }

  public onRolesChange(roles: Record<string, Role[]>): void {
    this.setSelectedRole(roles[this.props.organizationId] ?? []).then();
  }

  private async resolveCurrentRole(): Promise<void> {
    this.setState({
      loading: true,
    });
    const roles = await this.props.user.getOrganizationRoles(this.props.organizationId);
    await this.setSelectedRole(roles);
    this.setState({ loading: false });
  }

  private async setSelectedRole(rolesInCurrentOrganization: Role[]): Promise<void> {
    if (rolesInCurrentOrganization.length > 1) {
      console.warn(`User '${this.props.user.getId()}' has an unsupported amount of roles`);
    }
    // TODO: multi-selection for roles
    const selectedRole = rolesInCurrentOrganization[0];
    const isSelectedRoleAvailable = Boolean(
      !selectedRole || this.props.availableRoles.find((role) => selectedRole && selectedRole.equals(role))
    );
    const isCurrentUser = await this.props.user.isCurrentUser();

    this.setState({
      selectedRole,
      isCurrentUser,
      // if the role is not "available", it means that the current user does not have the necessary permission to change
      // the role and thus should not have any other edit rights to the user
      cannotEdit: !isSelectedRoleAvailable || isCurrentUser,
    });
  }

  private takeUserAction = (): void => {
    this.props.actionCallback(this.props.userAction, this.props.user);
  };

  private handlePermissionSelection = async (index?: number): Promise<void> => {
    const oldRole = this.state.selectedRole;
    const newRole = index != null ? this.props.availableRoles[index] : undefined;

    // no change
    if (oldRole?.identifier === newRole?.identifier) {
      return;
    }

    this.setState({ loading: true });

    const notAvailable = translations.common.texts.notAvailable();

    try {
      await this.props.user.assignOrganizationRoles(this.props.organizationId, newRole ? [newRole] : []);
      this.setState({ selectedRole: newRole });
    } catch (err) {
      console.error(`Could not assign ${newRole?.name ?? "Unknown"} role to user`, err);
      this.setState({
        error: translations.admin.texts.failedToAddUserToRole({
          roleName: newRole?.name ?? notAvailable,
        }),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  private getCurrentSelectionIndex(): number {
    const selectedRole = this.state.selectedRole;
    return this.props.availableRoles.findIndex((role) => selectedRole && selectedRole.equals(role));
  }

  private renderActionButton(): Maybe<JSX.Element> {
    let buttonText: Maybe<string>;

    if (this.props.userAction === UserOrganizationAction.DeleteUser) {
      buttonText = translations.common.buttons.delete();
    } else if (this.props.userAction === UserOrganizationAction.RemoveFromOrganization) {
      buttonText = translations.common.buttons.remove();
    }

    if (buttonText && this.state.cannotEdit === false) {
      return (
        <Button
          onClick={this.takeUserAction}
          variant="contained"
          color="primary"
          data-testid={`${this.props["data-testid"]}-delete-button`}
        >
          {buttonText}
        </Button>
      );
    }
  }

  private renderPermissions(): ReactNode {
    const selectedRole = this.state.selectedRole;
    if (this.state.loading) {
      return <Loader size="small" />;
    }

    if (this.state.cannotEdit || this.props.availableRoles.length < 2) {
      return <Typography variant={"subtitle1"}>{selectedRole ? translateRoleName(selectedRole) : ""}</Typography>;
    } else {
      return (
        <DropdownSelection
          onSelect={this.handlePermissionSelection}
          selectionList={this.props.availableRoles.map((role) => ({
            key: role.identifier,
            label: translateRoleName(role),
          }))}
          currentSelection={this.getCurrentSelectionIndex()}
          disabled={!this.props.enableRoleSelection}
          emptySelectionItem={!selectedRole ? translations.admin.inputs.noRoleSelected() : undefined}
          data-testid={`${this.props["data-testid"]}-user-permission-dropdown`}
          fullWidth={true}
          variant="standard"
        />
      );
    }
  }

  private renderUserName(): ReactNode {
    return (
      <AdminCellItem
        item={{ name: this.props.user.getEmail(), id: this.props.user.getId() }}
        data-testid={"user"}
        active={this.state.isCurrentUser}
      />
    );
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <TableRow key={this.props.user.getId()} data-testid={"table-row"}>
          <TableCell component="th" scope="row" data-testid={`${this.props["data-testid"]}`}>
            {this.renderUserName()}
          </TableCell>
          <TableCell align="center">{this.renderPermissions()}</TableCell>
          <TableCell align="right">{this.renderActionButton()}</TableCell>
        </TableRow>
        <ErrorDialog
          errorMsg={this.state.error}
          onClose={(): void => this.setState({ error: undefined })}
          data-testid="change-policy-group-error"
        />
      </Fragment>
    );
  }
}
