export var TextColor;
(function (TextColor) {
    TextColor["Black"] = "Black";
    TextColor["White"] = "White";
})(TextColor || (TextColor = {}));
export var CredentialDeliveryMethod;
(function (CredentialDeliveryMethod) {
    CredentialDeliveryMethod["Email"] = "EMAIL";
    CredentialDeliveryMethod["SMS"] = "SMS";
})(CredentialDeliveryMethod || (CredentialDeliveryMethod = {}));
export var ConfigurationSettingsPurpose;
(function (ConfigurationSettingsPurpose) {
    ConfigurationSettingsPurpose["MobileAccessCredential"] = "MOBILE_ACCESS_CREDENTIAL";
    ConfigurationSettingsPurpose["ControlCard"] = "CONTROL_CARD";
})(ConfigurationSettingsPurpose || (ConfigurationSettingsPurpose = {}));
export const controlCardProjectConfigType = 1;
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["InProgress"] = "IN_PROGRESS";
    OrderStatus["Completed"] = "COMPLETED";
    OrderStatus["Failed"] = "FAILED";
})(OrderStatus || (OrderStatus = {}));
class Timeout extends Error {
}
function isDateString(response) {
    return (response !== null &&
        response !== undefined &&
        typeof response === "string" &&
        new Date(response).toISOString() === response);
}
function isTextColor(response) {
    return (response !== null &&
        response !== undefined &&
        typeof response === "string" &&
        Object.entries(TextColor).find(([_key, value]) => value === response) !== undefined);
}
function isOrderTemplate(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.id) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.name) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.legicProjectId) === "number");
}
function isArrayOfOrderTemplates(response) {
    return Array.isArray(response) && response.every((value) => isOrderTemplate(value));
}
function isOrderSearchResult(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.orderId) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.credentialMatches) === "boolean");
}
function isArrayOfOrderSearchResults(response) {
    return Array.isArray(response) && response.every((value) => isOrderSearchResult(value));
}
function isSearchOrdersResponse(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.results) === "object" &&
        isArrayOfOrderSearchResults(response === null || response === void 0 ? void 0 : response.results));
}
function isOrganizationDto(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.id) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.name) === "string");
}
function isCredentialDto(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.registrationKey) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.credentialId) === "number" &&
        (!("deviceId" in response) || typeof (response === null || response === void 0 ? void 0 : response.deviceId) === "string") &&
        typeof (response === null || response === void 0 ? void 0 : response.status) === "string" &&
        isDateString(response === null || response === void 0 ? void 0 : response.statusTimestamp) &&
        (!("assignedTo" in response) || typeof (response === null || response === void 0 ? void 0 : response.assignedTo) === "string"));
}
function isArrayOfCredentialDtos(response) {
    return Array.isArray(response) && response.every((value) => isCredentialDto(value));
}
function isDetailedCredential(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.status) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.deviceId) === "string" &&
        (!("assignedTo" in response) || typeof (response === null || response === void 0 ? void 0 : response.assignedTo) === "string"));
}
function isCreateOrderResponse(response) {
    return (response !== null && typeof response === "object" && typeof (response === null || response === void 0 ? void 0 : response.orderId) === "number");
}
function isAssignCredentialResponse(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.newCredentialStatus) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.newAssignee) === "string");
}
function isCredentialContents(response) {
    return (response !== null &&
        typeof response === "object" &&
        Object.keys(response).every((key) => {
            const value = Reflect.get(response, key);
            return typeof value === "number";
        }));
}
function isOrderDto(response) {
    var _a, _b;
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.orderId) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.organizationName) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.startIndex) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.credentialCountTotal) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.credentialCountDeployed) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.purchaseOrder) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.programmerName) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.status) === "string" &&
        isDateString(response === null || response === void 0 ? void 0 : response.createdAt) &&
        (!response.completedAt || isDateString(response.completedAt)) &&
        typeof (response === null || response === void 0 ? void 0 : response.configurationSettingsId) === "string" &&
        (!("formatName" in response) || typeof (response === null || response === void 0 ? void 0 : response.formatName) === "string") &&
        isCredentialContents(response === null || response === void 0 ? void 0 : response.credentialContents) &&
        isArrayOfCredentialDtos(response === null || response === void 0 ? void 0 : response.credentials) &&
        typeof (response === null || response === void 0 ? void 0 : response.hasDownload) === "boolean" &&
        (!response.downloads ||
            (typeof ((_a = response === null || response === void 0 ? void 0 : response.downloads) === null || _a === void 0 ? void 0 : _a.archiveUrl) === "string" &&
                isDateString((_b = response.downloads) === null || _b === void 0 ? void 0 : _b.expiresAt))));
}
function isConfigurationSettingsDto(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.id) === "string" &&
        isDateString(response === null || response === void 0 ? void 0 : response.createdAt) &&
        typeof (response === null || response === void 0 ? void 0 : response.name) === "string" &&
        isOrganizationDto(response === null || response === void 0 ? void 0 : response.organization) &&
        isOrderTemplate(response === null || response === void 0 ? void 0 : response.orderTemplate) &&
        typeof (response === null || response === void 0 ? void 0 : response.purpose) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.modelNumber) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.orderCreationAllowed) === "boolean" &&
        typeof (response === null || response === void 0 ? void 0 : response.frontImageFileName) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.backImageFileName) === "string" &&
        (!("walletLogoFileName" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletLogoFileName) === "string") &&
        (!("walletIconFileName" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletIconFileName) === "string") &&
        (!("walletThumbnailFileName" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletThumbnailFileName) === "string") &&
        (!("walletBackgroundFileName" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletBackgroundFileName) === "string") &&
        typeof (response === null || response === void 0 ? void 0 : response.includeOrganizationNameInMac) === "boolean" &&
        typeof (response === null || response === void 0 ? void 0 : response.summaryPdfTemplateFileName) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.macPdfTemplateFileName) === "string" &&
        (!("attachmentFileName" in response) ||
            typeof (response === null || response === void 0 ? void 0 : response.attachmentFileName) === "string") &&
        (!("dateCodeX" in response) || typeof (response === null || response === void 0 ? void 0 : response.dateCodeX) === "number") &&
        (!("dateCodeY" in response) || typeof (response === null || response === void 0 ? void 0 : response.dateCodeY) === "number") &&
        (!("credentialIdX" in response) || typeof (response === null || response === void 0 ? void 0 : response.credentialIdX) === "number") &&
        (!("credentialIdY" in response) || typeof (response === null || response === void 0 ? void 0 : response.credentialIdY) === "number") &&
        (!("facilityCodeX" in response) || typeof (response === null || response === void 0 ? void 0 : response.facilityCodeX) === "number") &&
        (!("facilityCodeX" in response) || typeof (response === null || response === void 0 ? void 0 : response.facilityCodeX) === "number") &&
        (!("textColor" in response) || isTextColor(response === null || response === void 0 ? void 0 : response.textColor)) &&
        (!("textSize" in response) || typeof (response === null || response === void 0 ? void 0 : response.textSize) === "number") &&
        (!("walletBackgroundColor" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletBackgroundColor) === "string") &&
        (!("walletForegroundColor" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletForegroundColor) === "string") &&
        (!("walletLabelColor" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletLabelColor) === "string") &&
        (!("walletSecondaryLabelColor" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletSecondaryLabelColor) === "string") &&
        (!("controlCardType" in response) || typeof (response === null || response === void 0 ? void 0 : response.controlCardType) === "number") &&
        (!("controlCardProjectId" in response) ||
            typeof (response === null || response === void 0 ? void 0 : response.controlCardProjectId) === "number") &&
        (!("controlCardMaxSecureCode" in response) ||
            typeof (response === null || response === void 0 ? void 0 : response.controlCardMaxSecureCode) === "number"));
}
function isCredentialCardImages(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.frontImage) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.frontImage) === "string");
}
function isConfigFiles(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.frontImage) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.frontImage) === "string" &&
        (!("walletLogo" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletLogo) === "string") &&
        (!("walletIcon" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletIcon) === "string") &&
        (!("walletBackground" in response) || typeof (response === null || response === void 0 ? void 0 : response.walletBackground) === "string") &&
        typeof (response === null || response === void 0 ? void 0 : response.summaryPdfTemplate) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.macPdfTemplate) === "string" &&
        (!("attachment" in response) || typeof (response === null || response === void 0 ? void 0 : response.attachment) === "string"));
}
function isGenerateCardImagePreviewResponse(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.previewBase64) === "string");
}
function isFormatFieldDetails(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.name) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.minValue) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.maxValue) === "number");
}
function isFormat(response) {
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.name) === "string" &&
        "idField" in response &&
        isFormatFieldDetails(response === null || response === void 0 ? void 0 : response.idField) &&
        Array.isArray(response === null || response === void 0 ? void 0 : response.extraFields) &&
        (response === null || response === void 0 ? void 0 : response.extraFields.every((value) => isFormatFieldDetails(value))));
}
function isOrderSummaryDto(response) {
    var _a, _b;
    return (response !== null &&
        typeof response === "object" &&
        typeof (response === null || response === void 0 ? void 0 : response.id) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.organizationId) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.organizationName) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.status) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.credentialCountTotal) === "number" &&
        typeof (response === null || response === void 0 ? void 0 : response.purchaseOrder) === "string" &&
        typeof (response === null || response === void 0 ? void 0 : response.programmerName) === "string" &&
        isDateString(response === null || response === void 0 ? void 0 : response.createdAt) &&
        (!("downloads" in response) || typeof (response === null || response === void 0 ? void 0 : response.downloads) === "object") &&
        (!("downloads" in response) || typeof ((_a = response === null || response === void 0 ? void 0 : response.downloads) === null || _a === void 0 ? void 0 : _a.url) === "string") &&
        (!("downloads" in response) ||
            typeof ((_b = response === null || response === void 0 ? void 0 : response.downloads) === null || _b === void 0 ? void 0 : _b.validityDurationMilliseconds) === "number"));
}
function isArrayOfConfigurationSettingDtos(response) {
    return Array.isArray(response) && response.every((value) => isConfigurationSettingsDto(value));
}
function isArrayOfFormats(response) {
    return Array.isArray(response) && response.every((value) => isFormat(value));
}
function isArrayOfOrderSummaryDtos(response) {
    return Array.isArray(response) && response.every((value) => isOrderSummaryDto(value));
}
function isArrayOfNumbers(response) {
    return Array.isArray(response) && response.every((value) => typeof value === "number");
}
function isObject(response) {
    return response !== null && typeof response === "object";
}
function encodeRFC3986URIComponent(value) {
    // encodeURIComponent is not compliant with newer RFC3986, so we enhance it
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#encoding_for_rfc3986
    return encodeURIComponent(value).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
}
export class OrderApiClient {
    constructor(baseUrl, authTokenProvider) {
        this.authTokenProvider = authTokenProvider;
        this.baseUrl = `${baseUrl}/webui/v1`;
    }
    async getConfigurationSettingsById(configurationSettingsId) {
        const path = `configurations/${encodeURIComponent(configurationSettingsId)}`;
        const dto = await this.fetch("GET", path, isConfigurationSettingsDto);
        return Object.assign(Object.assign({}, dto), { createdAt: new Date(dto.createdAt), textColor: dto.textColor });
    }
    async getConfigurationSettingsForOrganization(organizationId, abortion) {
        const path = `organizations/${encodeURIComponent(organizationId)}/configurations`;
        const dtos = await this.fetch("GET", path, isArrayOfConfigurationSettingDtos, { abortion });
        return dtos.map((x) => {
            return Object.assign(Object.assign({}, x), { createdAt: new Date(x.createdAt), textColor: x.textColor });
        });
    }
    async getConfigurationSettingsForOrder(orderId) {
        const path = `orders/${orderId}/configuration`;
        const dto = await this.fetch("GET", path, isConfigurationSettingsDto);
        return Object.assign(Object.assign({}, dto), { createdAt: new Date(dto.createdAt), textColor: dto.textColor });
    }
    async createConfigurationSettings(config) {
        const path = "configurations";
        return await this.fetch("POST", path, isObject, { body: config });
    }
    async updateConfigurationSettings(config) {
        const path = "configurations";
        return await this.fetch("PUT", path, isObject, { body: config });
    }
    async getFilesForConfig(configurationSettingsId) {
        const path = `configurations/${encodeURIComponent(configurationSettingsId)}/files`;
        return await this.fetch("GET", path, isConfigFiles);
    }
    async getImagesForOrder(orderId) {
        const path = `orders/${orderId}/images`;
        return await this.fetch("GET", path, isCredentialCardImages);
    }
    async generateCardImagePreview(request, abortion) {
        const path = "previewCardImage";
        return await this.fetch("POST", path, isGenerateCardImagePreviewResponse, { body: request, abortion: abortion });
    }
    async generateCardImagePreviewForConfig(request) {
        const path = `configurations/${encodeURIComponent(request.configurationSettingsId)}/previewCardImages`;
        return await this.fetch("POST", path, isCredentialCardImages, { body: request });
    }
    async getFormats(organizationId) {
        const path = `organizations/${encodeURIComponent(organizationId)}/formats`;
        return await this.fetch("GET", path, isArrayOfFormats);
    }
    async assignFormats(formats, organizationId) {
        const path = `organizations/${encodeURIComponent(organizationId)}/formats`;
        return await this.fetch("PUT", path, isObject, { body: { newFormats: Array.from(formats) } });
    }
    async assignOrderTemplates(orderTemplateIds, organizationId) {
        const path = `organizations/${encodeURIComponent(organizationId)}/orderTemplates`;
        return await this.fetch("PUT", path, isObject, { body: { newOrderTemplates: Array.from(orderTemplateIds) } });
    }
    async getOrdersForOrganization(request, abortion) {
        const queryParams = new URLSearchParams();
        if (request.beginning !== undefined) {
            queryParams.set("beginning", encodeURIComponent(request.beginning.toISOString()));
        }
        if (request.end !== undefined) {
            queryParams.set("end", encodeURIComponent(request.end.toISOString()));
        }
        const path = `organizations/${encodeURIComponent(request.organizationId)}/orders?${queryParams}`;
        const dtos = await this.fetch("GET", path, isArrayOfOrderSummaryDtos, { abortion: abortion });
        return dtos.map((x) => {
            return Object.assign(Object.assign({}, x), { createdAt: new Date(x.createdAt) });
        });
    }
    async getOrder(orderId, timeoutSeconds) {
        const path = `orders/${orderId}?getDownloadUrl=true`;
        const orderDto = await this.fetch("GET", path, isOrderDto, {
            timeoutSeconds: timeoutSeconds !== null && timeoutSeconds !== void 0 ? timeoutSeconds : OrderApiClient.defaultTimeoutSeconds,
        });
        return Object.assign(Object.assign({}, orderDto), { createdAt: new Date(orderDto.createdAt), completedAt: orderDto.completedAt ? new Date(orderDto.completedAt) : undefined, credentials: orderDto.credentials.map((x) => {
                return Object.assign(Object.assign({}, x), { statusTimestamp: new Date(x.statusTimestamp) });
            }), downloads: orderDto.downloads
                ? {
                    archiveUrl: orderDto.downloads.archiveUrl,
                    expiresAt: new Date(orderDto.downloads.expiresAt),
                }
                : undefined });
    }
    async getCredential(orderId, credentialId, timeoutSeconds) {
        const path = `orders/${orderId}/credentials/${credentialId}`;
        return await this.fetch("GET", path, isDetailedCredential, {
            timeoutSeconds: timeoutSeconds !== null && timeoutSeconds !== void 0 ? timeoutSeconds : OrderApiClient.defaultTimeoutSeconds,
        });
    }
    async getOrderTemplates(organizationId) {
        const path = `organizations/${encodeURIComponent(organizationId)}/orderTemplates`;
        return await this.fetch("GET", path, isArrayOfOrderTemplates);
    }
    async searchOrders(request, abortion) {
        const queryParams = new URLSearchParams({ query: encodeRFC3986URIComponent(request.query) });
        if (request.beginning !== undefined) {
            queryParams.set("beginning", encodeURIComponent(request.beginning.toISOString()));
        }
        if (request.end !== undefined) {
            queryParams.set("end", encodeURIComponent(request.end.toISOString()));
        }
        const path = `orders/search?${queryParams}`;
        return await this.fetch("GET", path, isSearchOrdersResponse, { abortion });
    }
    async searchCredentials(query, orderId, abortion) {
        const path = `orders/${orderId}/search?query=${encodeRFC3986URIComponent(query)}`;
        return await this.fetch("GET", path, isArrayOfNumbers, { abortion });
    }
    async createOrder(order) {
        const path = "orders";
        return await this.fetch("POST", path, isCreateOrderResponse, { body: order });
    }
    async assignCredential(request) {
        const path = `orders/${request.orderId}/credentials/${request.credentialId}/assign`;
        return await this.fetch("POST", path, isAssignCredentialResponse, { body: request });
    }
    async fetch(method, path, responseTypeGuard, options) {
        var _a, _b, _c;
        const authToken = await this.authTokenProvider();
        const timeoutSeconds = (_a = options === null || options === void 0 ? void 0 : options.timeoutSeconds) !== null && _a !== void 0 ? _a : OrderApiClient.defaultTimeoutSeconds;
        const abortion = (_b = options === null || options === void 0 ? void 0 : options.abortion) !== null && _b !== void 0 ? _b : new AbortController();
        const timeout = setTimeout(() => abortion.abort(new Timeout(`Request to ${path} timed out after ${timeoutSeconds} seconds`)), timeoutSeconds * 1000);
        const url = `${this.baseUrl}/${path}`;
        const response = await fetch(url, {
            method: method,
            headers: {
                Authorization: authToken,
                "conekt-cloud-version": (_c = process.env.REACT_APP_WEBUI_VERSION) !== null && _c !== void 0 ? _c : "unknown",
            },
            signal: abortion.signal,
            body: (options === null || options === void 0 ? void 0 : options.body) ? JSON.stringify(options.body) : undefined,
        });
        clearTimeout(timeout);
        const responseContent = await response.json();
        if (response.status !== 200) {
            const errorDetail = "message" in responseContent
                ? responseContent.message
                : "messages" in responseContent
                    ? responseContent.messages.join("\n")
                    : "Unknown error";
            throw new Error(`Status code: ${response.status} \n ${errorDetail}`);
        }
        else if (!responseTypeGuard(responseContent)) {
            throw new Error(`Unexpected response from ${url}`);
        }
        return responseContent;
    }
}
OrderApiClient.defaultTimeoutSeconds = 30;
