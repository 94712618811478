import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { Component } from "react";
import { Maybe } from "@sade/data-access";

type Selection = {
  /**
   * Unique key
   */
  key: string;
  /**
   * Value to display
   */
  label: string | number | boolean;
  /**
   * Whether the value can be selected (or only shown).
   * Defaults to false.
   */
  disabled?: false | boolean;
};

interface Props {
  label?: string;
  disabled?: boolean;
  selectionList: Selection[];
  emptySelectionItem?: string;
  onSelect: (selection?: number) => void;
  currentSelection?: number;
  "data-testid"?: string;
  variant?: "standard" | "outlined" | "filled";
  fullWidth?: boolean;
  margin?: "none" | "dense" | "normal";
}

interface State {
  selected?: string;
}

export default class DropdownSelection extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      selected: this.getSelection(this.props.currentSelection),
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.currentSelection !== this.props.currentSelection) {
      this.setState({
        selected: this.getSelection(this.props.currentSelection),
      });
    }
  }

  private getSelection(index?: number): Maybe<string> {
    return index != null ? this.props.selectionList[index]?.key : undefined;
  }

  private getLabel(): Maybe<JSX.Element> {
    if (this.props.label) {
      return <InputLabel shrink={true}>{this.props.label}</InputLabel>;
    }
  }

  private getValue(): string {
    return this.state.selected ?? "";
  }

  private getSelectionList(): Maybe<JSX.Element[]> {
    if (this.props.selectionList && this.props.selectionList.length > 0) {
      return this.props.selectionList.map((selection: Selection, index: number) => {
        return (
          <MenuItem
            key={selection.key}
            value={selection.key}
            data-testid={this.props["data-testid"] ? `${this.props["data-testid"]}-${index}` : undefined}
            disabled={selection.disabled}
          >
            {selection.label}
          </MenuItem>
        );
      });
    }
  }

  private getEmptySelection(): Maybe<JSX.Element> {
    if (this.props.emptySelectionItem) {
      return <MenuItem value="">{this.props.emptySelectionItem}</MenuItem>;
    }
  }

  private handleSelection = (event: SelectChangeEvent): void => {
    let index: Maybe<number> = undefined;
    const value = event.target?.value;

    if (value != null) {
      const foundIndex = this.props.selectionList.findIndex((selection) => selection.key === value);
      index = foundIndex !== -1 ? foundIndex : undefined;
    }
    this.setState({ selected: value });
    this.props.onSelect(index);
  };

  public render(): JSX.Element {
    return (
      <FormControl margin={this.props.margin} fullWidth={this.props.fullWidth}>
        {this.getLabel()}
        <Select
          value={this.getValue()}
          onChange={this.handleSelection}
          disabled={this.props.disabled}
          displayEmpty={true}
          data-testid={this.props["data-testid"]}
          variant={this.props.variant}
          fullWidth={this.props.fullWidth}
        >
          {this.getEmptySelection()}
          {this.getSelectionList()}
        </Select>
      </FormControl>
    );
  }
}
