import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../../ui/loader";
import { Button, Checkbox, FormControlLabel, Grid, InputLabel, TextField } from "@mui/material";
import { AuthenticatedUser, AuthWrapper, isError, Maybe } from "@sade/data-access";
import PasswordField from "../../ui/password-field";
import { translations } from "../../../generated/translationHelper";
import PasswordRequirements from "./password-requirements";

interface Props {
  user: AuthenticatedUser;
  openTermsOfUse: () => void;
}

interface State {
  isLoaderVisible: boolean;
  newPassword: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
  firstNameError?: string;
  lastNameError?: string;
  generalError?: string;
  acceptTOS: boolean;
}

export default class CompleteAccountCreationForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      newPassword: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      acceptTOS: false,
    };
  }

  public componentDidMount(): void {
    this.setState({
      firstName: this.props.user.challengeParam?.userAttributes.given_name ?? "",
      lastName: this.props.user.challengeParam?.userAttributes.family_name ?? "",
    });
  }

  private handleErrors(code?: string): void {
    switch (code) {
      case "UserNotLoggedIn":
        this.setState({ generalError: translations.logIn.texts.userNotLoggedIn() });
        break;
      case "Password does not conform to policy: Password must have numeric characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveNumbers() });
        break;
      case "Password does not conform to policy: Password must have lowercase characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveLowercaseCharacters() });
        break;
      case "Password does not conform to policy: Password must have uppercase characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveUppercaseCharacters() });
        break;
      case "Password does not conform to policy: Password must satisfy regular expression pattern: ^\\S.*\\S$":
        this.setState({ generalError: translations.common.texts.passwordInvalid() });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password does not conform to policy: Password not long enough":
        this.setState({ newPasswordError: translations.common.texts.passwordMustBeLongEnough() });
        break;
      case "Provided password cannot be used for security reasons.":
        this.setState({ newPasswordError: translations.common.texts.passwordRejectedForSecurityReasons() });
        break;
      case "Invalid session for the user, session is expired.":
        this.setState({ generalError: translations.common.texts.userSessionExpired() });
        break;
      default:
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
        break;
    }
  }

  private resetErrors(): void {
    this.setState({
      generalError: undefined,
      newPasswordError: undefined,
      confirmPasswordError: undefined,
    });
  }

  private validate(): boolean {
    let isValid = true;

    if (!this.passwordsMatch()) {
      this.setState({ confirmPasswordError: translations.common.texts.passwordsNotMatching() });
      isValid = false;
    }

    //passwords of length 1 will throw a regex error. This is clearer to the user.
    if (this.state.newPassword.length < 2) {
      this.setState({ newPasswordError: translations.common.texts.passwordMustBeLongEnough() });
      isValid = false;
    }

    if (this.state.firstName.trim().length === 0) {
      this.setState({ firstNameError: translations.logIn.texts.firstNameCannotBeEmpty() });
      isValid = false;
    }

    if (this.state.lastName.trim().length === 0) {
      this.setState({ lastNameError: translations.logIn.texts.lastNameCannotBeEmpty() });
      isValid = false;
    }

    return isValid;
  }

  private async completeAccountCreation(): Promise<void> {
    this.resetErrors();

    try {
      if (this.validate()) {
        this.setState({ isLoaderVisible: true });
        await AuthWrapper.completeNewPassword(this.props.user, this.state.newPassword);
        await AuthWrapper.setName(this.state.firstName.trim(), this.state.lastName.trim());
      }
    } catch (error) {
      console.error("completePassword", error);
      if (isError(error)) {
        this.handleErrors(error.message);
      } else {
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
      }
    } finally {
      this.setState({ isLoaderVisible: false });
    }
  }

  private validateInputs = (): boolean => {
    return !!this.state.newPassword.length && !!this.state.confirmPassword.length && this.state.acceptTOS;
  };

  private passwordsMatch(): boolean {
    return this.state.newPassword === this.state.confirmPassword;
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.isLoaderVisible) {
      return <Loader />;
    }
  }

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <div className="field-container">
            <label htmlFor="password">{translations.common.inputs.newPassword()}</label>
            <PasswordField
              id="password"
              autoComplete="new-password"
              required={true}
              error={this.state.newPasswordError != null}
              helperText={this.state.newPasswordError}
              onChange={(newPassword: string): void => {
                this.resetErrors();
                this.setState({ newPassword });
              }}
              fullWidth={true}
              margin="none"
              inputProps={{ "data-testid": "new-password-field" }}
            />
          </div>
          <div className="field-container">
            <label htmlFor="confirm-password">{translations.common.inputs.confirmNewPassword()}</label>
            <PasswordField
              id="confirm-password"
              autoComplete="new-password"
              error={this.state.confirmPasswordError != null}
              helperText={this.state.confirmPasswordError}
              onChange={(confirmPassword: string): void => {
                this.resetErrors();
                this.setState({ confirmPassword });
              }}
              fullWidth={true}
              required={true}
              margin="none"
              inputProps={{ "data-testid": "confirm-password-field" }}
            />
          </div>
          <PasswordRequirements />
          <Grid container direction="column" className="field-container">
            <Grid item>
              <InputLabel htmlFor="first-name">{translations.logIn.inputs.firstname()} *</InputLabel>
              <TextField
                value={this.state.firstName}
                onChange={(e): void => {
                  this.setState({ firstName: e.target.value, firstNameError: undefined });
                }}
                id="first-name"
                variant="outlined"
                fullWidth
                margin="none"
                error={this.state.firstNameError !== undefined}
                helperText={this.state.firstNameError ?? " "}
              />
            </Grid>
            <Grid item>
              <InputLabel htmlFor="last-name">{translations.logIn.inputs.lastname()} *</InputLabel>
              <TextField
                value={this.state.lastName}
                onChange={(e): void => {
                  this.setState({ lastName: e.target.value, lastNameError: undefined });
                }}
                id="last-name"
                variant="outlined"
                fullWidth
                margin="none"
                error={this.state.lastNameError !== undefined}
                helperText={this.state.lastNameError ?? " "}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event: ChangeEvent, accept: boolean): void => {
                  this.setState({ acceptTOS: accept });
                }}
                data-testid="accept-tos"
                color="primary"
              />
            }
            label={
              <p className="tos-label">
                {translations.logIn.inputs.acceptTermsOfUse().split("<a>")[0]}
                <a href="#" onClick={this.props.openTermsOfUse}>
                  {translations.logIn.inputs.acceptTermsOfUse().split("<a>")[1]}
                </a>
                {translations.logIn.inputs.acceptTermsOfUse().split("<a>")[2]}
              </p>
            }
          />
          <div className="login-buttons">
            <Button
              disabled={!this.validateInputs()}
              variant="contained"
              color="primary"
              onClick={(): Promise<void> => this.completeAccountCreation()}
              data-testid="confirm-login-button"
            >
              {translations.logIn.buttons.submit()}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }

  private renderGeneralError(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext" data-testid="complete-account-err">
          {translations.common.texts.errorOccurred({ error: this.state.generalError })}
        </div>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="login-header">{translations.logIn.texts.finishAccount()}</div>
        {this.renderLoader()}
        {this.renderInputs()}
        {this.renderGeneralError()}
      </Fragment>
    );
  }
}
