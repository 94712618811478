import React, { JSX, useCallback, useEffect, useRef, useState } from "react";
import { ColorPicker } from "@wellbees/color-picker-input"
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  createFilterOptions,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { translations } from "../../../generated/translationHelper";
import {
  AuthWrapper,
  AwsConfiguration,
  ConfigurationSettings,
  ConfigurationSettingsPurpose,
  controlCardProjectConfigType,
  GenerateCardImagePreviewRequest,
  OrderApiClient,
  OrderTemplate,
  TextColor,
} from "@sade/data-access";
import { SelectItem, SelectWithLoading } from "../../ui/select-with-loading";
import { ValidationError } from "../../ui/validation-error";
import { FileUploadInput } from "./file-upload-input";
import { CardImageDisplay } from "../../ui/card-image-display";
import Loader from "../../ui/loader";
import { CoordinatesInput } from "./coordinates-input";
import { useNotification } from "../../ui/notification";
import { toBase64 } from "fast-base64";
import { OrganizationSelect } from "../../ui/organization-select";
import { ControlCardTypes } from "./ControlCardTypes";
import { isInteger } from "../../../utils/StringUtils";

export enum Mode {
  DetailsViewClosed = "",
  ViewExisting = "view",
  EditExisting = "edit",
  CreateNew = "create",
}

interface PropsForViewingExisting {
  mode: Mode.ViewExisting | Mode.EditExisting;
  configurationSettings: ConfigurationSettings;
  close: () => void;
  closeAndRefresh: () => void;
}

interface PropsForCreatingNew {
  mode: Mode.CreateNew;
  configurationSettings?: undefined;
  close: () => void;
  closeAndRefresh: () => void;
}

export type Props = PropsForCreatingNew | PropsForViewingExisting;

class ResultIsStale extends Error {}

function toHexString(value: number): string {
  return value.toString(16).padStart(2, "0").toUpperCase();
}

function asBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      resolve(toBase64(new Uint8Array(reader.result as ArrayBuffer)));
    };
    reader.onerror = (error): void => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
}

export const ConfigurationDetails: React.FC<Props> = (props: Props) => {
  const maxImageUploadSizeBytes = 250 * 1024;
  const maxAttachmentUploadSizeBytes = 3 * 1024 * 1024;

  const defaultDateCodeX = 1050;
  const defaultDateCodeY = 700;
  const defaultCredentialIdX = 60;
  const defaultCredentialIdY = 700;
  const defaultFacilityCodeX = 580;
  const defaultFacilityCodeY = 700;

  const { mode, configurationSettings } = props;

  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const previewGenerationDebounceTimer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const previewGenerationAbortion = useRef<AbortController | undefined>(undefined);
  const displayNotification = useNotification();

  const [organization, setOrganization] = useState(configurationSettings?.organization);

  function resolveControlCardTypeName(ccTypeHex: string): string | undefined {
    const ccType = Number.parseInt(ccTypeHex, 16);
    if (!Number.isNaN(ccType)) {
      return ControlCardTypes.find((cct) => cct.controlByteValue === ccType)?.name;
    } else {
      return undefined;
    }
  }

  const getOrderTemplates = useCallback(async (): Promise<OrderTemplate[]> => {
    if (organization !== undefined) {
      return await apiClient.current.getOrderTemplates(organization.id);
    } else {
      return [];
    }
  }, [organization]);
  const getSelectableOrderTemplates = useCallback(async (): Promise<SelectItem<OrderTemplate>[]> => {
    const orderTemplates = await getOrderTemplates();
    return orderTemplates.map((t) => ({ id: t.id, name: `${t.name} (${t.id})`, item: { ...t } }));
  }, [getOrderTemplates]);
  const getSelectableProjectIds = useCallback(async (): Promise<SelectItem<number>[]> => {
    const orderTemplates = await getOrderTemplates();
    return orderTemplates
      .map((t) => t.legicProjectId)
      .reduce(
        (uniques: number[], projectId) => (uniques.includes(projectId) ? uniques : uniques.concat([projectId])),
        []
      )
      .map((projectId) => ({ id: projectId, name: projectId.toString(), item: projectId }));
  }, [getOrderTemplates]);

  function renderTextSizeMenu(): JSX.Element[] {
    const sizes: number[] = [];
    for (let i = 25; i <= 75; ++i) {
      sizes.push(i);
    }
    return sizes.map((s) => (
      <MenuItem value={s} key={s}>
        {s}
      </MenuItem>
    ));
  }

  function renderTextColorMenu(): JSX.Element[] {
    return Object.entries(TextColor).map(([key, value]) => (
      <MenuItem value={value} key={key}>
        {key}
      </MenuItem>
    ));
  }

  const [name, setName] = useState(configurationSettings?.name);
  const [orderTemplate, setOrderTemplate] = useState(configurationSettings?.orderTemplate);
  const [modelNumber, setModelNumber] = useState(configurationSettings?.modelNumber ?? "");
  const [purpose, setPurpose] = useState(
    configurationSettings?.purpose ?? ConfigurationSettingsPurpose.MobileAccessCredential
  );
  const [controlCardTypeHex, setControlCardTypeHex] = useState(
    configurationSettings?.controlCardType !== undefined ? toHexString(configurationSettings?.controlCardType) : ""
  );
  const [controlCardProjectId, setControlCardProjectId] = useState<number | undefined>(
    configurationSettings?.controlCardProjectId !== 0 ? configurationSettings?.controlCardProjectId : undefined // treat 0 as undefined to make validation easier
  );
  const [controlCardMaxSecureCode, setControlCardMaxSecureCode] = useState<number | undefined>(
    configurationSettings?.controlCardMaxSecureCode ?? 0
  );
  const [frontImageFileName, setFrontImageFileName] = useState(configurationSettings?.frontImageFileName);
  const [backImageFileName, setBackImageFileName] = useState(configurationSettings?.backImageFileName);
  const [walletLogoFileName, setWalletLogoFileName] = useState<string | undefined>(configurationSettings?.walletLogoFileName);
  const [walletIconFileName, setWalletIconFileName] = useState<string | undefined>(configurationSettings?.walletIconFileName);
  const [walletThumbnailFileName, setWalletThumbnailFileName] = useState<string | undefined>(configurationSettings?.walletThumbnailFileName);
  const [walletBackgroundFileName, setWalletBackgroundFileName] = useState<string | undefined>(configurationSettings?.walletBackgroundFileName);
  const [walletSealFileName, setWalletSealFileName] = useState<string | undefined>(undefined);
  const [includeOrganizationNameInMac, setIncludeOrganizationNameInMac] = useState(
    configurationSettings?.includeOrganizationNameInMac ?? true
  );
  const [summaryPdfTemplateFileName, setSummaryPdfTemplateFileName] = useState(
    configurationSettings?.summaryPdfTemplateFileName
  );
  const [macPdfTemplateFileName, setMacPdfTemplateFileName] = useState(configurationSettings?.macPdfTemplateFileName);
  const [attachmentFileName, setAttachmentFileName] = useState(configurationSettings?.attachmentFileName);
  const [frontImage, setFrontImage] = useState<string | undefined>(undefined);
  const [backImage, setBackImage] = useState<string | undefined>(undefined);
  const [walletLogo, setWalletLogo] = useState<string | undefined>(undefined);
  const [walletIcon, setWalletIcon] = useState<string | undefined>(undefined);
  const [walletThumbnail, setWalletThumbnail] = useState<string | undefined>(undefined);
  const [walletBackground, setWalletBackground] = useState<string | undefined>(undefined);
  const [walletSeal, setWalletSeal] = useState<string | undefined>(undefined);
  const [walletBackgroundColor, setWalletBackgroundColor] = useState(configurationSettings?.walletBackgroundColor ?? "#FFFFFF");
  const [walletForegroundColor, setWalletForegroundColor] = useState(configurationSettings?.walletBackgroundColor ?? "#000000");
  const [walletLabelColor, setWalletLabelColor] = useState(configurationSettings?.walletBackgroundColor ?? "#000000");
  const [walletSecondaryLabelColor, setWalletSecondaryLabelColor] = useState(configurationSettings?.walletBackgroundColor ?? "#000000");
  const [frontImagePreview, setFrontImagePreview] = useState<string | undefined>(undefined);
  const [previewLoadingCount, setPreviewLoadingCount] = useState(0);
  const [summaryPdfTemplate, setSummaryPdfTemplate] = useState<string | undefined>(undefined);
  const [macPdfTemplate, setMacPdfTemplate] = useState<string | undefined>(undefined);
  const [attachment, setAttachment] = useState<string | undefined>(undefined);
  const [attachmentEnabled, setAttachmentEnabled] = useState(configurationSettings?.attachmentFileName !== undefined);
  const [dateCodeX, setDateCodeX] = useState<number | undefined>(configurationSettings?.dateCodeX ?? defaultDateCodeX);
  const [dateCodeY, setDateCodeY] = useState<number | undefined>(configurationSettings?.dateCodeY ?? defaultDateCodeY);
  const [dateCodeEnabled, setDateCodeEnabled] = useState(
    mode === Mode.CreateNew ||
      configurationSettings?.dateCodeX !== undefined ||
      configurationSettings?.dateCodeY !== undefined
  );
  const [credentialIdX, setCredentialIdX] = useState<number | undefined>(
    configurationSettings?.credentialIdX ?? defaultCredentialIdX
  );
  const [credentialIdY, setCredentialIdY] = useState<number | undefined>(
    configurationSettings?.credentialIdY ?? defaultCredentialIdY
  );
  const [credentialEnabled, setCredentialEnabled] = useState(
    mode === Mode.CreateNew ||
      configurationSettings?.credentialIdX !== undefined ||
      configurationSettings?.credentialIdY !== undefined
  );
  const [facilityCodeX, setFacilityCodeX] = useState<number | undefined>(
    configurationSettings?.facilityCodeX ?? defaultFacilityCodeX
  );
  const [facilityCodeY, setFacilityCodeY] = useState<number | undefined>(
    configurationSettings?.facilityCodeY ?? defaultFacilityCodeY
  );
  const [facilityCodeEnabled, setFacilityCodeEnabled] = useState(
    mode === Mode.CreateNew ||
      configurationSettings?.facilityCodeX !== undefined ||
      configurationSettings?.facilityCodeY !== undefined
  );
  const [textColor, setTextColor] = useState(configurationSettings?.textColor ?? TextColor.Black);
  const [textSize, setTextSize] = useState(configurationSettings?.textSize ?? 50);
  const [isLoading, setIsLoading] = useState(false);
  const [filesAreLoading, setFilesAreLoading] = useState(mode === Mode.EditExisting);

  const [nameValidationError, setNameValidationError] = useState<string | undefined>(undefined);
  const [organizationValidationError, setOrganizationValidationError] = useState<string | undefined>(undefined);
  const [orderTemplateValidationError, setOrderTemplateValidationError] = useState<string | undefined>(undefined);
  const [controlCardTypeError, setControlCardTypeError] = useState<string | undefined>(undefined);
  const [controlCardProjectIdError, setControlCardProjectIdError] = useState<string | undefined>(undefined);
  const [controlCardMaxSecureCodeError, setControlCardMaxSecureCodeError] = useState<string | undefined>(undefined);
  const [frontImageValidationError, setFrontImageValidationError] = useState<string | undefined>(undefined);
  const [backImageValidationError, setBackImageValidationError] = useState<string | undefined>(undefined);
  const [walletLogoValidationError, setWalletLogoValidationError] = useState<string | undefined>(undefined);
  const [walletIconValidationError, setWalletIconValidationError] = useState<string | undefined>(undefined);
  const [walletThumbnailValidationError, setWalletThumbnailValidationError] = useState<string | undefined>(undefined);
  const [walletBackgroundValidationError, setWalletBackgroundValidationError] = useState<string | undefined>(undefined);
  const [summaryTemplateValidationError, setSummaryTemplateValidationError] = useState<string | undefined>(undefined);
  const [certificateTemplateValidationError, setCertificateTemplateValidationError] = useState<string | undefined>(
    undefined
  );
  const [attachmentValidationError, setAttachmentValidationError] = useState<string | undefined>(undefined);
  const [dateCodeValidationError, setDateCodeValidationError] = useState<string | undefined>(undefined);
  const [facilityCodeValidationError, setFacilityCodeValidationError] = useState<string | undefined>(undefined);
  const [credentialIdValidationError, setCredentialIdValidationError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (mode !== Mode.CreateNew) {
      setFilesAreLoading(true);
      apiClient.current
        .getFilesForConfig(props.configurationSettings.id)
        .then((newFiles) => {
          if (backImage === undefined) {
            setBackImage(newFiles.backImage);
          }
          if (frontImage === undefined) {
            setFrontImage(newFiles.frontImage);
          }
          if (walletLogo === undefined) {
            setWalletLogo(newFiles.walletLogo);
          }
          if (walletIcon === undefined) {
            setWalletIcon(newFiles.walletIcon);
          }
          if (walletBackground === undefined) {
            setWalletBackground(newFiles.walletBackground);
          }
          if (summaryPdfTemplate === undefined) {
            setSummaryPdfTemplate(newFiles.summaryPdfTemplate);
          }
          if (macPdfTemplate === undefined) {
            setMacPdfTemplate(newFiles.macPdfTemplate);
          }
          if (attachment === undefined) {
            setAttachment(newFiles.attachment);
          }
        })
        .catch((e) => {
          const message = e instanceof Error ? e.message : translations.orders.texts.unknownError();
          displayNotification({
            title: translations.configurations.texts.loadingFilesFailed(),
            message: message,
            variant: "error",
          });
        })
        .finally(() => setFilesAreLoading(false));
    }
    // we purposefully want to run this only once after config ID changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationSettings?.id]);

  const generateCardImagePreview = useCallback(
    async (request: GenerateCardImagePreviewRequest): Promise<void> => {
      function resultsAreStillRelevant(abortion: AbortController): boolean {
        return !abortion.signal.aborted || !(abortion.signal.reason instanceof ResultIsStale);
      }

      const abortion = new AbortController();
      try {
        previewGenerationAbortion.current?.abort(new ResultIsStale());
        previewGenerationAbortion.current = abortion;

        setPreviewLoadingCount((previousValue) => {
          if (previousValue < 0) {
            console.error("Incrementing preview loading operation count from below zero, that should never happen");
          }
          return previousValue + 1;
        });

        const preview = await apiClient.current.generateCardImagePreview(request, abortion);

        if (resultsAreStillRelevant(abortion)) {
          setFrontImagePreview(preview.previewBase64);
        } else {
          console.debug("Card image preview discarded as stale");
        }
      } catch (e) {
        if (resultsAreStillRelevant(abortion)) {
          const message = e instanceof Error ? e.message : translations.orders.texts.unknownError();
          displayNotification({
            title: translations.configurations.texts.generatingCardImagePreviewFailed(),
            message: message,
            variant: "error",
          });

          setFrontImagePreview(frontImage);
        } else {
          console.debug("Card image preview generation interrupted, result would have been stale", e);
        }
      } finally {
        setPreviewLoadingCount((previousValue) => {
          if (previousValue <= 0) {
            console.error("Decrementing preview loading operation count to below zero, that should never happen");
          }
          return previousValue - 1;
        });
      }
    },
    [frontImage, displayNotification]
  );

  useEffect(() => {
    if (previewGenerationDebounceTimer.current !== undefined) {
      clearTimeout(previewGenerationDebounceTimer.current);
    }
    const debounceDurationSeconds = 0.8;

    if (frontImage !== undefined) {
      const request = {
        backgroundBase64: frontImage,
        dateCodeX: dateCodeEnabled ? dateCodeX : undefined,
        dateCodeY: dateCodeEnabled ? dateCodeY : undefined,
        facilityCodeX: facilityCodeEnabled ? facilityCodeX : undefined,
        facilityCodeY: facilityCodeEnabled ? facilityCodeY : undefined,
        credentialIdX: credentialEnabled ? credentialIdX : undefined,
        credentialIdY: credentialEnabled ? credentialIdY : undefined,
        textColor: textColor,
        textSize: textSize,
      };

      previewGenerationDebounceTimer.current = setTimeout(() => {
        generateCardImagePreview(request).catch((e) => {
          console.error("Error when generating card image preview", e);
        });
      }, debounceDurationSeconds * 1000);
    } else {
      setFrontImagePreview(undefined);
    }

    return (): void => {
      if (previewGenerationDebounceTimer.current !== undefined) {
        clearTimeout(previewGenerationDebounceTimer.current);
      }
    };
  }, [
    frontImage,
    dateCodeEnabled,
    dateCodeX,
    dateCodeY,
    facilityCodeEnabled,
    facilityCodeX,
    facilityCodeY,
    credentialEnabled,
    credentialIdX,
    credentialIdY,
    textColor,
    textSize,
    generateCardImagePreview,
  ]);

  function validateControlCardType(hexValue: string): { isValid: boolean; message: string } {
    let isValid = true;
    let message = "";

    const controlCardType = Number.parseInt(hexValue, 16);

    if (hexValue.trim().length <= 0) {
      isValid = false;
      message = translations.configurations.texts.valueMustBeProvided();
    }

    if (isValid && Number.isNaN(controlCardType)) {
      isValid = false;
      message = translations.configurations.texts.valueMustBeGivenInHex();
    }

    const min = 1;
    const max = 255;
    if (isValid && (controlCardType < min || controlCardType > max)) {
      isValid = false;
      message = translations.configurations.texts.valueMustBeInRange({
        min: `${toHexString(min)}`,
        max: `${toHexString(max)}`,
      });
    }

    return { isValid, message };
  }

  function resolveControlCardType(
    hexValue: string,
    configPurpose: string
  ): { isValid: boolean; controlCardType: number; detailsRequired: boolean; validationError: string } {
    const controlCardType = Number.parseInt(hexValue, 16);
    const finalControlCardType = configPurpose === ConfigurationSettingsPurpose.ControlCard ? controlCardType : 0;
    const detailsRequired = finalControlCardType === controlCardProjectConfigType;

    const validationResult = validateControlCardType(hexValue);

    return {
      isValid: validationResult.isValid || configPurpose !== ConfigurationSettingsPurpose.ControlCard,
      controlCardType: finalControlCardType,
      detailsRequired: detailsRequired,
      validationError: validationResult.message,
    };
  }

  async function submit(): Promise<void> {
    const finalDateCodeX = dateCodeEnabled ? dateCodeX : undefined;
    const finalDateCodeY = dateCodeEnabled ? dateCodeY : undefined;
    const finalFacilityCodeX = facilityCodeEnabled ? facilityCodeX : undefined;
    const finalFacilityCodeY = facilityCodeEnabled ? facilityCodeY : undefined;
    const finalCredentialIdX = credentialEnabled ? credentialIdX : undefined;
    const finalCredentialIdY = credentialEnabled ? credentialIdY : undefined;
    const finalAttachment = attachmentEnabled
      ? {
          content: attachment as string,
          fileName: attachmentFileName as string,
        }
      : undefined;
    const finalControlCardType = resolveControlCardType(controlCardTypeHex, purpose);
    const finalControlCardProjectId = finalControlCardType.detailsRequired ? controlCardProjectId! : 0;
    const finalControlCardMaxSecureCode = finalControlCardType.detailsRequired ? controlCardMaxSecureCode! : 0;

    const request = {
      id: undefined as unknown as string,
      name: name as string,
      organizationId: organization?.id as string,
      orderTemplateId: orderTemplate?.id as number,
      purpose: purpose,
      modelNumber: modelNumber,
      frontImageFileName: frontImageFileName as string,
      frontImage: frontImage as string,
      backImageFileName: backImageFileName as string,
      backImage: backImage as string,
      walletLogoFileName: walletLogoFileName as string,
      walletLogo: walletLogo as string,
      walletIconFileName: walletIconFileName as string,
      walletIcon: walletIcon as string,
      walletThumbnailFileName: walletThumbnailFileName as string,
      walletThumbnail: walletThumbnail as string,
      walletBackgroundFileName: walletBackgroundFileName as string,
      walletBackground: walletBackground as string,
      includeOrganizationNameInMac: includeOrganizationNameInMac,
      macPdfTemplateFileName: macPdfTemplateFileName as string,
      macPdfTemplate: macPdfTemplate as string,
      summaryPdfTemplateFileName: summaryPdfTemplateFileName as string,
      summaryPdfTemplate: summaryPdfTemplate as string,
      attachment: finalAttachment,
      includeQrCodeInMac: true,
      dateCodeX: finalDateCodeX,
      dateCodeY: finalDateCodeY,
      facilityCodeX: finalFacilityCodeX,
      facilityCodeY: finalFacilityCodeY,
      credentialIdX: finalCredentialIdX,
      credentialIdY: finalCredentialIdY,
      textColor: textColor,
      textSize: textSize,
      walletBackgroundColor: walletBackgroundColor,
      walletForegroundColor: walletForegroundColor,
      walletLabelColor: walletLabelColor,
      walletSecondaryLabelColor: walletSecondaryLabelColor,
      controlCardType: finalControlCardType.controlCardType,
      controlCardProjectId: finalControlCardProjectId,
      controlCardMaxSecureCode: finalControlCardMaxSecureCode,
    };

    if (mode === Mode.EditExisting) {
      request.id = configurationSettings.id;
      await apiClient.current.updateConfigurationSettings(request);
    } else {
      await apiClient.current.createConfigurationSettings(request);
    }
  }

  async function validateAndSubmit(): Promise<void> {
    function validateMandatory<TValue>(value: TValue | undefined, setMessage: (msg: string) => void): boolean {
      if (value !== undefined) {
        return true;
      } else {
        setMessage(translations.orders.texts.valueMustBeProvided());
        return false;
      }
    }

    try {
      setIsLoading(true);

      const controlCardType = resolveControlCardType(controlCardTypeHex, purpose);
      const isValidName = validateMandatory(name, setNameValidationError);
      const isValidOrganization = validateMandatory(organization, setOrganizationValidationError);
      const isValidOrderTemplate = validateMandatory(orderTemplate, setOrderTemplateValidationError);
      const isValidControlCardProjectId =
        !controlCardType.detailsRequired || validateMandatory(controlCardProjectId, setControlCardProjectIdError);
      const isValidControlCardMaxSecureCode =
        !controlCardType.detailsRequired ||
        validateMandatory(controlCardMaxSecureCode, setControlCardMaxSecureCodeError);
      const isValidFrontImageFileName = validateMandatory(frontImageFileName, setFrontImageValidationError);
      const isValidFrontImage = validateMandatory(frontImage, setFrontImageValidationError);
      const isValidBackImageFileName = validateMandatory(backImageFileName, setBackImageValidationError);
      const isValidBackImage = validateMandatory(backImage, setBackImageValidationError);
      const isValidWalletLogo = validateMandatory(walletLogo, setWalletLogoValidationError);
      const isValidWalletIcon = validateMandatory(walletIcon, setWalletIconValidationError);
      const isValidWalletThumbnail = validateMandatory(walletThumbnail, setWalletThumbnailValidationError);
      const isValidWalletBackground = validateMandatory(walletBackground, setWalletBackgroundValidationError);
      const isValidSummaryPdfTemplateFileName = validateMandatory(
        summaryPdfTemplateFileName,
        setSummaryTemplateValidationError
      );
      const isValidSummaryPdfTemplate = validateMandatory(summaryPdfTemplate, setSummaryTemplateValidationError);
      const isValidMacPdfTemplateFileName = validateMandatory(
        macPdfTemplateFileName,
        setCertificateTemplateValidationError
      );
      const isValidMacPdfTemplate = validateMandatory(macPdfTemplate, setCertificateTemplateValidationError);
      const isValidAttachmentFileName =
        !attachmentEnabled || validateMandatory(attachmentFileName, setAttachmentValidationError);
      const isValidAttachment = !attachmentEnabled || validateMandatory(attachment, setAttachmentValidationError);

      if (!controlCardType.isValid) {
        setControlCardTypeError(controlCardType.validationError);
      }

      const isValidDateCode = !dateCodeEnabled || (dateCodeX !== undefined && dateCodeY !== undefined);
      const isValidFacilityCode = !facilityCodeEnabled || (facilityCodeX !== undefined && facilityCodeY !== undefined);
      const isValidCredentialId = !credentialEnabled || (credentialIdX !== undefined && credentialIdY !== undefined);
      if (!isValidDateCode) {
        setDateCodeValidationError(
          translations.configurations.texts.mustProvideBothCoordinates({
            fieldName: translations.configurations.inputs.dateCode(),
          })
        );
      }
      if (!isValidFacilityCode) {
        setFacilityCodeValidationError(
          translations.configurations.texts.mustProvideBothCoordinates({
            fieldName: translations.configurations.inputs.facilityCode(),
          })
        );
      }
      if (!isValidCredentialId) {
        setCredentialIdValidationError(
          translations.configurations.texts.mustProvideBothCoordinates({
            fieldName: translations.configurations.inputs.credentialId(),
          })
        );
      }

      const allValid =
        isValidName &&
        isValidOrganization &&
        isValidOrderTemplate &&
        controlCardType.isValid &&
        isValidControlCardProjectId &&
        isValidControlCardMaxSecureCode &&
        isValidFrontImageFileName &&
        isValidFrontImage &&
        isValidBackImageFileName &&
        isValidBackImage &&
        isValidWalletLogo &&
        isValidWalletIcon &&
        isValidWalletThumbnail &&
        isValidWalletBackground &&
        isValidSummaryPdfTemplateFileName &&
        isValidSummaryPdfTemplate &&
        isValidMacPdfTemplateFileName &&
        isValidMacPdfTemplate &&
        isValidAttachmentFileName &&
        isValidAttachment &&
        isValidDateCode &&
        isValidFacilityCode &&
        isValidCredentialId;

      if (allValid) {
        await submit();

        const message =
          mode === Mode.CreateNew
            ? translations.configurations.texts.creatingConfigurationSucceeded()
            : translations.configurations.texts.editingConfigurationSucceeded();
        displayNotification({
          title: name as string,
          message: message,
          variant: "success",
        });

        props.closeAndRefresh();
      }
    } catch (e) {
      const title =
        mode === Mode.CreateNew
          ? translations.configurations.texts.creatingConfigurationFailed()
          : translations.configurations.texts.editingConfigurationFailed();
      const message = e instanceof Error ? e.message : translations.orders.texts.unknownError();
      displayNotification({
        title: title,
        message: message,
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }

  const controlCardType = Number.parseInt(controlCardTypeHex, 16);
  return (
    <Grid
      container
      direction="column"
      rowSpacing={2}
      sx={{ padding: "0px 24px", margin: "auto" }}
      xs={12}
      sm={10}
      md={6}
      lg={10}
      xl={12}
    >
      <Grid item container direction="row" columnGap={1.5}>
        <Grid item container direction="column" rowSpacing={1}>
          <Grid item>
            <Button variant="text" startIcon={<ArrowBack />} onClick={props.close}>
              {mode === Mode.ViewExisting
                ? translations.configurations.buttons.backToList()
                : translations.common.buttons.cancel()}
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {mode === Mode.ViewExisting
                ? name
                : mode === Mode.EditExisting
                ? translations.configurations.texts.editConfiguration({ configurationName: configurationSettings.name })
                : translations.configurations.texts.createConfiguration()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" columnSpacing={5}>
        <Grid item container direction="column" rowGap={1} xs={12} lg={6} xl={3}>
          <Grid item>
            <Typography variant="h6" className="config-details-section-heading">
              Basics
            </Typography>
          </Grid>
          <Box>
            {mode === Mode.ViewExisting ? (
              <>
                <InputLabel>{translations.configurations.texts.customer()}</InputLabel>
                <Typography>{organization?.name}</Typography>
              </>
            ) : (
              <OrganizationSelect
                selectedOrganizationId={organization?.id}
                enableSelectingAll={false}
                selectOrganization={async (o): Promise<void> => {
                  setOrganization(o);
                  setOrganizationValidationError(undefined);
                }}
                validationErrorMessage={organizationValidationError ?? " "}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={!includeOrganizationNameInMac}
                  onChange={(e, v): void => setIncludeOrganizationNameInMac(!v)}
                  color="primary"
                  size="small"
                  sx={{ marginTop: -0.5 }}
                  disabled={mode === Mode.ViewExisting}
                />
              }
              label={translations.configurations.inputs.removeOrganizationNameFromMac()}
              sx={{
                marginLeft: 0.5,
                marginBottom: 1,
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            />
          </Box>
          <Box>
            <InputLabel htmlFor="name">{translations.configurations.texts.configurationName()}</InputLabel>
            {mode === Mode.ViewExisting ? (
              <Typography id="name">{name}</Typography>
            ) : (
              <>
                <TextField
                  value={name ?? ""}
                  onChange={(event): void => {
                    setName(event.target.value);
                    setNameValidationError(undefined);
                  }}
                  size="small"
                  fullWidth
                  id="name"
                />
                <ValidationError>{nameValidationError ?? " "}</ValidationError>
              </>
            )}
          </Box>
          <Box>
            {mode === Mode.ViewExisting ? (
              <>
                <InputLabel id="label-orderTemplate-select">
                  {translations.configurations.texts.orderTemplate()}
                </InputLabel>
                <Typography>
                  {orderTemplate?.name} ({orderTemplate?.id})
                </Typography>
              </>
            ) : (
              <SelectWithLoading
                label={translations.configurations.texts.orderTemplate()}
                selectedId={orderTemplate?.id}
                enableSelectingAll={false}
                select={async (ot): Promise<void> => {
                  setOrderTemplate(ot);
                  setOrderTemplateValidationError(undefined);
                }}
                loadItems={getSelectableOrderTemplates}
                validationErrorMessage={orderTemplateValidationError ?? " "}
              />
            )}
          </Box>
          <Box>
            <InputLabel id="label-modelNumber">{translations.configurations.inputs.modelNumber()}</InputLabel>
            {mode === Mode.ViewExisting ? (
              <Typography id="modelNumber">{modelNumber.length > 0 ? modelNumber : "\u00a0"}</Typography>
            ) : (
              <>
                <TextField
                  value={modelNumber}
                  onChange={(event): void => {
                    setModelNumber(event.target.value ?? "");
                  }}
                  size="small"
                  fullWidth
                  id="modelNumber"
                />
                <ValidationError>{"\u00a0"}</ValidationError>
              </>
            )}
          </Box>
          <Box>
            <InputLabel id="label-purpose-select">{translations.configurations.inputs.purpose()}</InputLabel>
            {mode === Mode.ViewExisting ? (
              <Typography>{purpose}</Typography>
            ) : (
              <>
                <Select
                  id="purpose-select"
                  labelId="label-purpose-select"
                  value={purpose ?? ""}
                  onChange={async (event: SelectChangeEvent): Promise<void> => {
                    setPurpose(event.target.value as ConfigurationSettingsPurpose);
                  }}
                  displayEmpty={false}
                  style={{ width: "100%" }}
                  size="small"
                >
                  <MenuItem
                    value={ConfigurationSettingsPurpose.MobileAccessCredential}
                    key={ConfigurationSettingsPurpose.MobileAccessCredential}
                  >
                    {translations.configurations.inputs.mobileAccessCredential()}
                  </MenuItem>
                  <MenuItem
                    value={ConfigurationSettingsPurpose.ControlCard}
                    key={ConfigurationSettingsPurpose.ControlCard}
                  >
                    {translations.configurations.inputs.controlCard()}
                  </MenuItem>
                </Select>
                <ValidationError> </ValidationError>
              </>
            )}
          </Box>
          {purpose === ConfigurationSettingsPurpose.ControlCard ? (
            <>
              <Box>
                <InputLabel id="label-cctype-select">{translations.configurations.inputs.controlCardType()}</InputLabel>
                {mode === Mode.ViewExisting ? (
                  <Typography>
                    {controlCardTypeHex} (
                    {resolveControlCardTypeName(controlCardTypeHex) ?? translations.configurations.texts.unknown()})
                  </Typography>
                ) : (
                  <>
                    <Autocomplete
                      value={controlCardTypeHex}
                      onChange={(_, newValue): void => {
                        const input = newValue?.trim() ?? "";
                        setControlCardTypeHex(input);
                        const validationResult = validateControlCardType(input);
                        if (validationResult.isValid) {
                          setControlCardTypeError(undefined);
                        } else {
                          setControlCardTypeError(validationResult.message);
                        }
                        setControlCardProjectIdError(undefined);
                        setControlCardMaxSecureCodeError(undefined);
                      }}
                      options={ControlCardTypes.map((cct) => toHexString(cct.controlByteValue)).sort()}
                      filterOptions={(options, params): string[] => {
                        const filtered = createFilterOptions<string>()(options, params);
                        const { inputValue } = params;

                        if (resolveControlCardTypeName(inputValue) === undefined) {
                          // user typed a value that is not among our predefined options, let's add the user given value to be listed in the menu
                          filtered.push(inputValue);
                        }

                        return filtered;
                      }}
                      getOptionLabel={(option): string => {
                        if (RegExp(/^[0-9A-Fa-f]{1,2}$/).test(option)) {
                          // value is a valid hex string between 0 and FF
                          return `${option} — ${
                            resolveControlCardTypeName(option) ?? translations.configurations.texts.unknown()
                          }`;
                        } else {
                          return option;
                        }
                      }}
                      freeSolo
                      autoSelect
                      selectOnFocus
                      handleHomeEndKeys
                      size="small"
                      fullWidth
                      renderInput={(params): React.ReactNode => <TextField {...params} />}
                      id="ccmaxsecure"
                    />
                    {<ValidationError>{controlCardTypeError ?? "\u00a0"}</ValidationError>}
                  </>
                )}
              </Box>
              {controlCardType === controlCardProjectConfigType || mode !== Mode.ViewExisting ? (
                <>
                  <Box>
                    {mode === Mode.ViewExisting ? (
                      <>
                        <InputLabel id="label-ccpid">{translations.configurations.inputs.projectId()}</InputLabel>
                        <Typography>{controlCardProjectId}</Typography>
                      </>
                    ) : (
                      <SelectWithLoading
                        label={translations.configurations.inputs.projectId()}
                        selectedId={controlCardProjectId}
                        enableSelectingAll={false}
                        select={async (projectId): Promise<void> => {
                          setControlCardProjectId(projectId);
                          setControlCardProjectIdError(undefined);
                        }}
                        loadItems={getSelectableProjectIds}
                        disabled={Number.isNaN(controlCardType) || controlCardType !== controlCardProjectConfigType}
                        validationErrorMessage={controlCardProjectIdError ?? " "}
                      />
                    )}
                  </Box>
                  <Box>
                    <InputLabel id="label-ccmaxsecure">
                      {translations.configurations.inputs.newMaxSecureCode()}
                    </InputLabel>
                    {mode === Mode.ViewExisting ? (
                      <Typography>{controlCardMaxSecureCode}</Typography>
                    ) : (
                      <>
                        <TextField
                          value={controlCardMaxSecureCode?.toString() ?? ""}
                          onChange={(e): void => {
                            if (isInteger(e.target.value)) {
                              setControlCardMaxSecureCode(parseInt(e.target.value));
                              setControlCardMaxSecureCodeError(undefined);
                            } else if (e.target.value === "") {
                              setControlCardMaxSecureCode(undefined);
                            }
                          }}
                          disabled={Number.isNaN(controlCardType) || controlCardType !== controlCardProjectConfigType}
                          size="small"
                          fullWidth
                          id="ccmaxsecure"
                        />
                        <ValidationError>{controlCardMaxSecureCodeError ?? " "}</ValidationError>
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item container direction="column" rowGap={1} xs={12} lg={6} xl={3}>
          <Grid item>
            <Typography variant="h6" className="config-details-section-heading">
              Apple / Google Wallet Graphics
            </Typography>
          </Grid>
            <Box>
              <InputLabel htmlFor="config_walletLogo_fileName">
                Logo (858x150)
              </InputLabel>
              <FileUploadInput
                mode={mode}
                fileName={walletLogoFileName}
                fileType="image/png"
                isLoading={filesAreLoading}
                selectFile={async (file: File): Promise<void> => {
                  try {
                    setFilesAreLoading(true);
                    if (file.size <= maxImageUploadSizeBytes) {
                      setWalletLogoFileName(file.name);
                      setWalletLogo(await asBase64(file));
                      setWalletLogoValidationError(undefined);
                    } else {
                      setWalletLogoFileName(undefined);
                      setWalletLogo(undefined);
                      setWalletLogoValidationError(
                        translations.configurations.texts.fileSizeTooBigInKiB({
                          maxSizeKiloBytes: maxImageUploadSizeBytes / 1024,
                        })
                      );
                    }
                  } finally {
                    setFilesAreLoading(false);
                  }
                }}
                id="config_walletLogo_fileName"
              />
              <ValidationError>{walletLogoValidationError ?? " "}</ValidationError>
            </Box>
            <Box>
              <InputLabel htmlFor="config_walletIcon_fileName">
                Icon (200x200)
              </InputLabel>
              <FileUploadInput
                mode={mode}
                fileName={walletIconFileName}
                fileType="image/png"
                isLoading={filesAreLoading}
                selectFile={async (file: File): Promise<void> => {
                  try {
                    setFilesAreLoading(true);
                    if (file.size <= maxImageUploadSizeBytes) {
                      setWalletIconFileName(file.name);
                      setWalletIcon(await asBase64(file));
                      setWalletIconValidationError(undefined);
                    } else {
                      setWalletIconFileName(undefined);
                      setWalletIcon(undefined);
                      setWalletIconValidationError(
                        translations.configurations.texts.fileSizeTooBigInKiB({
                          maxSizeKiloBytes: maxImageUploadSizeBytes / 1024,
                        })
                      );
                    }
                  } finally {
                    setFilesAreLoading(false);
                  }
                }}
                id="config_walletIcon_fileName"
              />
              <ValidationError>{walletIconValidationError ?? " "}</ValidationError>
            </Box>
          <Box>
            <InputLabel htmlFor="config_walletThumbnail_fileName">
              Thumbnail (124x212)
            </InputLabel>
            <FileUploadInput
              mode={mode}
              fileName={walletThumbnailFileName}
              fileType="image/png"
              isLoading={filesAreLoading}
              selectFile={async (file: File): Promise<void> => {
                try {
                  setFilesAreLoading(true);
                  if (file.size <= maxImageUploadSizeBytes) {
                    setWalletThumbnailFileName(file.name);
                    setWalletThumbnail(await asBase64(file));
                    setWalletThumbnailValidationError(undefined);
                  } else {
                    setWalletThumbnailFileName(undefined);
                    setWalletThumbnail(undefined);
                    setWalletThumbnailValidationError(
                      translations.configurations.texts.fileSizeTooBigInKiB({
                        maxSizeKiloBytes: maxImageUploadSizeBytes / 1024,
                      })
                    );
                  }
                } finally {
                  setFilesAreLoading(false);
                }
              }}
              id="config_walletThumbnail_fileName"
            />
            <ValidationError>{walletThumbnailValidationError ?? " "}</ValidationError>
          </Box>
            <Box>
              <InputLabel htmlFor="config_walletBackground_fileName">
                Background (1536x969)
              </InputLabel>
              <FileUploadInput
                mode={mode}
                fileName={walletBackgroundFileName}
                fileType="image/png"
                isLoading={filesAreLoading}
                selectFile={async (file: File): Promise<void> => {
                  try {
                    setFilesAreLoading(true);
                    if (file.size <= maxImageUploadSizeBytes) {
                      setWalletBackgroundFileName(file.name);
                      setWalletBackground(await asBase64(file));
                      setWalletBackgroundValidationError(undefined);
                    } else {
                      setWalletBackgroundFileName(undefined);
                      setWalletBackground(undefined);
                      setWalletBackgroundValidationError(
                        translations.configurations.texts.fileSizeTooBigInKiB({
                          maxSizeKiloBytes: maxImageUploadSizeBytes / 1024,
                        })
                      );
                    }
                  } finally {
                    setFilesAreLoading(false);
                  }
                }}
                id="config_walletBackground_fileName"
              />
              <ValidationError>{walletBackgroundValidationError ?? " "}</ValidationError>
            </Box>
          <Box>
            <InputLabel htmlFor="config_walletSeal_fileName">
              Background seal (1024x768)
            </InputLabel>
            <FileUploadInput
              mode={mode}
              fileName={walletSealFileName}
              fileType="image/png"
              isLoading={filesAreLoading}
              selectFile={async (file: File): Promise<void> => {
                try {
                  setFilesAreLoading(true);
                  if (file.size <= maxImageUploadSizeBytes) {
                    setWalletSealFileName(file.name);
                    setWalletSeal(await asBase64(file));
                  } else {
                    setWalletSealFileName(undefined);
                    setWalletSeal(undefined);
                  }
                } finally {
                  setFilesAreLoading(false);
                }
              }}
              id="config_walletSeal_fileName"
            />
            <ValidationError>{"\u00a0"}</ValidationError>
          </Box>
            <Grid container direction="row" columnGap={1} justifyContent="space-between">
              <Grid item container direction="column" xs={5.75} rowGap={1}>
                <Grid item container direction="column">
                  <InputLabel htmlFor="config_background_color">
                    Background color
                  </InputLabel>
                  <ColorPicker
                    value={walletBackgroundColor}
                    inputType="mui"
                    onChange={(newColor: string): void => setWalletBackgroundColor(newColor)}
                    size="small"
                    colorShowType="circle"
                    fullWidth
                    id="config_background_color"
                  />
                </Grid>
                <Grid item container direction="column">
                  <InputLabel htmlFor="config_foreground_color">
                    Foreground color
                  </InputLabel>
                  <ColorPicker
                    value={walletForegroundColor}
                    inputType="mui"
                    onChange={(newColor: string): void => setWalletForegroundColor(newColor)}
                    size="small"
                    colorShowType="circle"
                    fullWidth
                    id="config_foreground_color"
                  />
                </Grid>
              </Grid>
              <Grid item container direction="column" xs={5.75} rowGap={1}>
                <Grid item container direction="column">
                  <InputLabel htmlFor="config_label_color">
                    Label color
                  </InputLabel>
                  <ColorPicker
                    value={walletLabelColor}
                    inputType="mui"
                    onChange={(newColor: string): void => setWalletLabelColor(newColor)}
                    size="small"
                    colorShowType="circle"
                    fullWidth
                    id="config_label_color"
                  />
                </Grid>
                <Grid item container direction="column">
                  <InputLabel htmlFor="config_secondaryLabel_color">
                    Secondary label color
                  </InputLabel>
                  <ColorPicker
                    value={walletSecondaryLabelColor}
                    inputType="mui"
                    onChange={(newColor: string): void => setWalletSecondaryLabelColor(newColor)}
                    size="small"
                    colorShowType="circle"
                    fullWidth
                    id="config_secondaryLabel_color"
                  />
                </Grid>
              </Grid>
            </Grid>
        </Grid>
        <Grid item container direction="column" rowGap={1} xs={12} lg={6} xl={3}>
          <Grid item>
            <Typography variant="h6" className="config-details-section-heading">
              Graphics
            </Typography>
          </Grid>
          <Box>
            <InputLabel htmlFor="config_frontImage_fileName">
              {translations.configurations.texts.frontBadge()}
            </InputLabel>
            <FileUploadInput
              mode={mode}
              fileName={frontImageFileName}
              fileType="image/png"
              isLoading={filesAreLoading}
              selectFile={async (file: File): Promise<void> => {
                try {
                  setFilesAreLoading(true);
                  if (file.size <= maxImageUploadSizeBytes) {
                    setFrontImageFileName(file.name);
                    setFrontImage(await asBase64(file));
                    setFrontImageValidationError(undefined);
                  } else {
                    setFrontImageFileName(undefined);
                    setFrontImage(undefined);
                    setFrontImageValidationError(
                      translations.configurations.texts.fileSizeTooBigInKiB({
                        maxSizeKiloBytes: maxImageUploadSizeBytes / 1024,
                      })
                    );
                  }
                } finally {
                  setFilesAreLoading(false);
                }
              }}
              id="config_frontImage_fileName"
            />
            <ValidationError>{frontImageValidationError ?? " "}</ValidationError>
          </Box>
          <Box>
            <InputLabel htmlFor="config_backImage_fileName">{translations.configurations.texts.backBadge()}</InputLabel>
            <FileUploadInput
              mode={mode}
              fileName={backImageFileName}
              fileType="image/png"
              isLoading={filesAreLoading}
              selectFile={async (file: File): Promise<void> => {
                try {
                  setFilesAreLoading(true);
                  if (file.size <= maxImageUploadSizeBytes) {
                    setBackImageFileName(file.name);
                    setBackImage(await asBase64(file));
                    setBackImageValidationError(undefined);
                  } else {
                    setBackImageFileName(undefined);
                    setBackImage(undefined);
                    setBackImageValidationError(
                      translations.configurations.texts.fileSizeTooBigInKiB({
                        maxSizeKiloBytes: maxImageUploadSizeBytes / 1024,
                      })
                    );
                  }
                } finally {
                  setFilesAreLoading(false);
                }
              }}
              id="config_backImage_fileName"
            />
            <ValidationError>{backImageValidationError ?? " "}</ValidationError>
          </Box>
          <Box>
            <InputLabel htmlFor="config_summary_fileName">{translations.configurations.texts.summary()}</InputLabel>
            <FileUploadInput
              mode={mode}
              fileName={summaryPdfTemplateFileName}
              fileType="image/png"
              isLoading={filesAreLoading}
              selectFile={async (file: File): Promise<void> => {
                try {
                  setFilesAreLoading(true);
                  if (file.size <= maxImageUploadSizeBytes) {
                    setSummaryPdfTemplateFileName(file.name);
                    setSummaryPdfTemplate(await asBase64(file));
                    setSummaryTemplateValidationError(undefined);
                  } else {
                    setSummaryPdfTemplateFileName(undefined);
                    setSummaryPdfTemplate(undefined);
                    setSummaryTemplateValidationError(
                      translations.configurations.texts.fileSizeTooBigInKiB({
                        maxSizeKiloBytes: maxImageUploadSizeBytes / 1024,
                      })
                    );
                  }
                } finally {
                  setFilesAreLoading(false);
                }
              }}
              id="config_summary_fileName"
            />
            <ValidationError>{summaryTemplateValidationError ?? " "}</ValidationError>
          </Box>
          <Box>
            <InputLabel htmlFor="config_certificate_fileName">
              {translations.configurations.texts.certificates()}
            </InputLabel>
            <FileUploadInput
              mode={mode}
              fileName={macPdfTemplateFileName}
              fileType="image/png"
              isLoading={filesAreLoading}
              selectFile={async (file: File): Promise<void> => {
                try {
                  setFilesAreLoading(true);
                  if (file.size <= maxImageUploadSizeBytes) {
                    setMacPdfTemplateFileName(file.name);
                    setMacPdfTemplate(await asBase64(file));
                    setCertificateTemplateValidationError(undefined);
                  } else {
                    setMacPdfTemplateFileName(undefined);
                    setMacPdfTemplate(undefined);
                    setCertificateTemplateValidationError(
                      translations.configurations.texts.fileSizeTooBigInKiB({
                        maxSizeKiloBytes: maxImageUploadSizeBytes / 1024,
                      })
                    );
                  }
                } finally {
                  setFilesAreLoading(false);
                }
              }}
              id="config_certificate_fileName"
            />
            <ValidationError>{certificateTemplateValidationError ?? " "}</ValidationError>
          </Box>
          <Grid item container direction="column">
            <Grid item>
              <InputLabel htmlFor="config_attachment_fileName">
                {translations.configurations.texts.attachment()}
              </InputLabel>
            </Grid>
            <Grid item container direction="row">
              <Grid item xs={1} display={mode === Mode.ViewExisting ? "none" : "unset"}>
                <Checkbox
                  checked={attachmentEnabled}
                  onChange={(e, v): void => {
                    setAttachmentEnabled(v);
                    setAttachmentValidationError(undefined);
                  }}
                  color="primary"
                  size="small"
                />
              </Grid>
              <Grid item xs={11}>
                <FileUploadInput
                  mode={mode}
                  fileName={
                    mode !== Mode.ViewExisting || attachmentFileName !== undefined ? attachmentFileName : "(none)"
                  }
                  isLoading={filesAreLoading}
                  disabled={!attachmentEnabled}
                  textFieldWidth={9.5}
                  buttonWidth={2.25}
                  selectFile={async (file: File): Promise<void> => {
                    try {
                      setFilesAreLoading(true);
                      if (file.size <= maxAttachmentUploadSizeBytes) {
                        setAttachmentFileName(file.name);
                        setAttachment(await asBase64(file));
                        setAttachmentValidationError(undefined);
                      } else {
                        setAttachmentFileName(undefined);
                        setAttachment(undefined);
                        setAttachmentValidationError(
                          translations.configurations.texts.fileSizeTooBigInMiB({
                            maxSizeMegaBytes: maxAttachmentUploadSizeBytes / (1024 * 1024),
                          })
                        );
                      }
                    } finally {
                      setFilesAreLoading(false);
                    }
                  }}
                  id="config_attachment_fileName"
                />
              </Grid>
              <ValidationError>{attachmentValidationError ?? "\u00a0"}</ValidationError>
            </Grid>
          </Grid>
          {mode === Mode.ViewExisting ? (
            <></>
          ) : (
            <Grid item container direction="column" rowGap={1} width={385}>
              <CoordinatesInput
                label={translations.configurations.inputs.dateCode()}
                y={dateCodeY}
                x={dateCodeX}
                setX={(value?: number): void => {
                  setDateCodeX(value);
                  setDateCodeValidationError(undefined);
                }}
                setY={(value?: number): void => {
                  setDateCodeY(value);
                  setDateCodeValidationError(undefined);
                }}
                enabled={dateCodeEnabled}
                onEnabledChange={(value: boolean): void => {
                  setDateCodeEnabled(value);
                  setDateCodeValidationError(undefined);
                }}
                validationErrorMessage={dateCodeValidationError}
              />
              <CoordinatesInput
                label={translations.configurations.inputs.credentialId()}
                x={credentialIdX}
                y={credentialIdY}
                setX={(value?: number): void => {
                  setCredentialIdX(value);
                  setCredentialIdValidationError(undefined);
                }}
                setY={(value?: number): void => {
                  setCredentialIdY(value);
                  setCredentialIdValidationError(undefined);
                }}
                enabled={credentialEnabled}
                onEnabledChange={(value: boolean): void => {
                  setCredentialEnabled(value);
                  setCredentialIdValidationError(undefined);
                }}
                validationErrorMessage={credentialIdValidationError}
              />
              <CoordinatesInput
                label={translations.configurations.inputs.facilityCode()}
                x={facilityCodeX}
                y={facilityCodeY}
                setX={(value?: number): void => {
                  setFacilityCodeX(value);
                  setFacilityCodeValidationError(undefined);
                }}
                setY={(value?: number): void => {
                  setFacilityCodeY(value);
                  setFacilityCodeValidationError(undefined);
                }}
                enabled={facilityCodeEnabled}
                onEnabledChange={(value: boolean): void => {
                  setFacilityCodeEnabled(value);
                  setFacilityCodeValidationError(undefined);
                }}
                validationErrorMessage={facilityCodeValidationError}
              />
              <Grid item container direction="row" justifyContent="space-between">
                <Grid item xs={6} paddingRight={1}>
                  <InputLabel id="font-size-select">{translations.configurations.inputs.fontSize()}</InputLabel>
                  <Select
                    value={textSize?.toString() ?? ""}
                    labelId="font-size-select"
                    onChange={(event): void => setTextSize(parseInt(event.target.value))}
                    displayEmpty={false}
                    style={{ width: "100%" }}
                    size="small"
                  >
                    {renderTextSizeMenu()}
                  </Select>
                </Grid>
                <Grid item xs={6} paddingLeft={1}>
                  <InputLabel id="font-color-select">{translations.configurations.inputs.fontColor()}</InputLabel>
                  <Select
                    value={textColor ?? ""}
                    labelId="font-color-select"
                    onChange={(event): void => setTextColor(event.target.value as TextColor)}
                    displayEmpty={false}
                    style={{ width: "100%" }}
                    size="small"
                  >
                    {renderTextColorMenu()}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item container direction="column" rowGap={1} xs={12} lg={6} xl={3}>
          <Grid item>
            <CardImageDisplay
              frontImage={frontImagePreview}
              backImage={backImage}
              hasLoadingAnimation={mode !== Mode.CreateNew || frontImage !== undefined || backImage !== undefined}
              showLoaderOverlay={previewLoadingCount > 0}
              width={385}
              renderHeading={(headingText: string): JSX.Element => {
                return (
                  <Typography variant="h6" className="config-details-section-heading">
                    {headingText}
                  </Typography>
                );
              }}
            />
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Grid item container direction="row" justifyContent="space-evenly">
              <Box>
                {(walletIcon && <img width={100} height={100} src={`data:image/png;base64,${walletIcon}`} />)}
              </Box>
              <Box>
                {(walletThumbnail && <img width={62} height={106} src={`data:image/png;base64,${walletThumbnail}`} />)}
              </Box>
            </Grid>
            <Box>
              {(walletLogo && <img width={286} height={50} src={`data:image/png;base64,${walletLogo}`} />)}
            </Box>
            <Box>
              {(walletBackground && <img width={256} height={162} src={`data:image/png;base64,${walletBackground}`} />)}
            </Box>
            <Box>
              {(walletSeal && <img width={256} height={192} src={`data:image/png;base64,${walletSeal}`} />)}
            </Box>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-end" columnGap={1} sx={{ paddingTop: "20px" }}>
            <Button variant="outlined" color="primary" onClick={props.close}>
              {translations.common.buttons.cancel()}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={validateAndSubmit}
              disabled={isLoading || filesAreLoading}
            >
              <Loader
                size={1}
                topBottomPadding={"0"}
                leftRightPadding={"0"}
                hidden={!isLoading}
                styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
              />
              <span style={{ visibility: isLoading ? "hidden" : "visible" }}>
                    {translations.common.buttons.save()}
                  </span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
