import React, { Component, Fragment, ReactNode } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { Maybe, Organization } from "@sade/data-access";
import Loader from "../../../ui/loader";
import ErrorDialog from "../../../ui/error-dialog";
import accessControlled from "../../../access-control/access-controlled";
import { translations } from "../../../../generated/translationHelper";
import { isInteger } from "../../../../utils/StringUtils";

interface Props {
  organization: Organization;
}

interface State {
  loading: boolean;
  displayName: string;
  maxSecureCode: string;
  errorMsg?: string;
}

const ACTextField = accessControlled(TextField, ["organizationsUpdate"]);

const ROW_COL_COUNT = 3;
export default class OrganizationDetails extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      displayName: props.organization.getName(),
      maxSecureCode: this.props.organization.getMaxSecureCode().toString(),
    };
  }

  public async componentDidMount(): Promise<void> {
    this.setState({
      displayName: this.props.organization.getName(),
      maxSecureCode: this.props.organization.getMaxSecureCode().toString(),
    });
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      this.setState({
        displayName: this.props.organization.getName(),
        maxSecureCode: this.props.organization.getMaxSecureCode().toString(),
      });
    }
  }

  private displayNameChanged = (newInput: string): void => {
    this.setState({ displayName: newInput });
  };

  private maxSecureCodeChanged = (newInput: string): void => {
    this.setState({ maxSecureCode: newInput });
  };

  private handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === "Enter") {
      return this.submitUpdateOrganization();
    }
  };

  private handleSubmit = async (): Promise<void> => {
    return this.submitUpdateOrganization();
  };

  private async submitUpdateOrganization(): Promise<void> {
    this.setState({ loading: true });

    try {
      if (isInteger(this.state.maxSecureCode)) {
        await this.props.organization.changeProps(this.state.displayName, parseInt(this.state.maxSecureCode));
        this.setState({
          loading: false,
          errorMsg: undefined,
          displayName: this.props.organization.getName(),
          maxSecureCode: this.props.organization.getMaxSecureCode().toString(),
        });
      } else {
        this.setState({ errorMsg: translations.admin.notificationErrors.maxSecureCodeMustBeNumber(), loading: false });
      }
    } catch (error) {
      console.error("submitNameChange", error);
      this.setState({ loading: false });
    }
  }

  private cancelUpdateOrganization = (): void => {
    this.setState({
      displayName: this.props.organization.getName(),
      maxSecureCode: this.props.organization.getMaxSecureCode().toString(),
    });
  };

  private renderNameControllers(): ReactNode {
    const disabled =
      this.state.displayName === this.props.organization.getName() &&
      this.state.maxSecureCode === this.props.organization.getMaxSecureCode().toString();
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleSubmit}
          style={{ marginRight: "2rem" }}
          disabled={disabled}
          data-testid="save-org-name-change-button"
        >
          {translations.common.buttons.save()}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={this.cancelUpdateOrganization}
          disabled={disabled}
          data-testid="cancel-org-name-change-button"
        >
          {translations.common.buttons.cancel()}
        </Button>
      </div>
    );
  }

  private renderLoader(): Maybe<JSX.Element> {
    if (this.state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <Loader size="small" />
          </TableCell>
        </TableRow>
      );
    }
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={ROW_COL_COUNT}>{translations.admin.texts.organisationDetails()}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <ACTextField
                  contextOrganization={this.props.organization}
                  accessDeniedProps={{ disabled: true }}
                  fullWidth={true}
                  variant="outlined"
                  placeholder={translations.admin.inputs.organisationName()}
                  className="organization-name"
                  value={this.state.displayName}
                  onChange={(event): void => this.displayNameChanged(event.currentTarget.value)}
                  onKeyPress={this.handleKeyPress}
                  inputProps={{ "data-testid": "curr-org-name-field" }}
                />
              </TableCell>
              <TableCell>
                <ACTextField
                  contextOrganization={this.props.organization}
                  accessDeniedProps={{ disabled: true }}
                  variant="outlined"
                  placeholder={translations.admin.inputs.maxSecureCode()}
                  className="organization-name"
                  value={this.state.maxSecureCode}
                  onChange={(event): void => this.maxSecureCodeChanged(event.currentTarget.value)}
                  onKeyDown={this.handleKeyPress}
                  inputProps={{ "data-testid": "curr-org-maxsecurecode-field" }}
                />
              </TableCell>
              <TableCell align="right" size="small">
                {this.renderNameControllers()}
              </TableCell>
            </TableRow>
            {this.renderLoader()}
          </TableBody>
        </Table>
        <ErrorDialog errorMsg={this.state.errorMsg} onClose={(): void => this.setState({ errorMsg: undefined })} />
      </Fragment>
    );
  }
}
