import React, { FunctionComponent, ReactElement } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";

interface Props {
  label: string;
  value: string;
  onEdit: () => void;
  editDisabled?: boolean;
}

export const ProfileFieldItem: FunctionComponent<Props> = (props: Props): ReactElement => {
  return (
    <Grid container className="field-item-grid-container">
      <Grid item xs container className="field-item-grid-item-container">
        <Grid item container direction="column" className="field-item-grid-item-column-container">
          <Grid item className="field-item-grid-item">
            <Typography variant="body2" color="textSecondary">
              {props.label.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item className="field-item-grid-item">
            <Typography variant="body1" color="textPrimary">
              {props.value}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="field-item-grid-item">
        <IconButton onClick={(): void => props.onEdit()} sx={{ visibility: props.editDisabled ? "hidden" : "visible" }}>
          <EditOutlined className="field-button-icon" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
