import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import React, { Component } from "react";
import ErrorDialog from "../../../ui/error-dialog";
import accessControlled from "../../../access-control/access-controlled";
import { isError, Organization, Role, RoleIdentifiers } from "@sade/data-access";
import Loader from "../../../ui/loader";
import DropdownSelection from "../../../ui/dropdown-selection";
import { isValidEmail } from "../../../../utils/validation";
import { translations } from "../../../../generated/translationHelper";
import { translateRoleName } from "./translate-role-name";

interface Props {
  organization: Organization;
  availableRoles: Role[];
}

interface State {
  loading: boolean;
  selectedRole?: number;
  email?: string;
  errorMsg?: string;
}

export class NewUserForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedRole: Math.max(
        props.availableRoles.findIndex((r) => r.identifier === RoleIdentifiers.Programmer),
        0
      ),
    };
  }

  private isValidUsername(): boolean {
    return !!this.state.email && isValidEmail(this.state.email);
  }

  private isValidRoleSelection(): boolean {
    return this.state.selectedRole != null;
  }

  private handleClickNewUser = async (): Promise<void> => {
    if (!this.isValidUsername()) {
      this.setState({ errorMsg: translations.admin.texts.invalidEmailAddress() });
      return;
    }

    if (!this.isValidRoleSelection()) {
      this.setState({ errorMsg: translations.admin.texts.roleMustBeSelected() });
      return;
    }

    // TODO: multi-select for roles
    const role = this.props.availableRoles[this.state.selectedRole!];

    this.setState({ loading: true });

    try {
      await this.props.organization.createUser({
        email: this.state.email!,
        resendInvitation: false,
        roles: [role],
      });
    } catch (error) {
      let reason = translations.admin.texts.failedToCreateUser();

      if (isError(error) && error.message.search("account already exists") >= 0) {
        reason = translations.admin.texts.userAlreadyExists();
      }
      this.setState({
        errorMsg: reason,
      });
    } finally {
      this.setState({
        email: undefined,
        loading: false,
        selectedRole: 0,
      });
    }
  };

  public render(): JSX.Element {
    return (
      <div className="new-user-form">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translations.admin.texts.createNewUser()}</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth={true}
                  label={translations.common.inputs.email()}
                  variant="outlined"
                  inputProps={{ "data-testid": "create-user-field" }}
                  type="email"
                  value={this.state.email ?? ""}
                  onChange={(event): void => this.setState({ email: event.currentTarget.value })}
                />
              </TableCell>
              <TableCell align="center">
                <DropdownSelection
                  label={translations.admin.inputs.role()}
                  onSelect={(index): void => this.setState({ selectedRole: index })}
                  selectionList={this.props.availableRoles.map((role) => ({
                    key: role.identifier,
                    label: translateRoleName(role),
                  }))}
                  currentSelection={this.state.selectedRole}
                  data-testid="user-permission-dropdown"
                  fullWidth={true}
                  variant="standard"
                />
              </TableCell>
              <TableCell align="right">
                {this.state.loading ? (
                  <Loader />
                ) : (
                  <Button
                    onClick={this.handleClickNewUser}
                    variant="contained"
                    color="primary"
                    data-testid="create-user-button"
                    disabled={!this.isValidRoleSelection() || !this.isValidUsername()}
                  >
                    {translations.common.buttons.create()}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={(): void => this.setState({ errorMsg: undefined })}
          data-testid="create-user-error"
        />
      </div>
    );
  }
}

export default accessControlled(NewUserForm, ["usersCreate"]);
