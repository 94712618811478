import React, { Component, FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from "react";
import { Grid, Tab, Tabs, Typography } from "@mui/material";
import { BackendFactory, Organization, Role, User } from "@sade/data-access";
import InlineBox from "../ui/inline-box";
import TabPanel from "../ui/tab-panel";
import ViewAccessMethods from "../../ViewAccessMethods";
import OrganizationSelector from "../ui/organization-selector";
import Permissions from "./components/permissions";
import OrganizationManagement from "./components/organization-management";
import UserList from "./components/user-list";
import accessControlled from "../access-control/access-controlled";
import { translations } from "../../generated/translationHelper";
import { Formats } from "./components/formats";
import { OrderTemplates } from "./components/orderTemplates";
import Loader from "../ui/loader";
import { AccountTreeOutlined, GroupOutlined, SourceOutlined, SpaceDashboardOutlined } from "@mui/icons-material";

interface Props {}

interface State {
  selectedTab: number;
  homeOrganization?: Organization;
  selectedOrganization?: Organization;
  currentUser?: User;
  allRoles?: Role[];
  searchFilter?: string;
  errorMsg?: string;
  loading: boolean;
}

type TabPageProps = { currentPage: number; index: number };

const TabPage: FunctionComponent<PropsWithChildren<TabPageProps>> = (
  props: PropsWithChildren<TabPageProps>
): ReactElement => {
  return (
    <TabPanel value={props.currentPage} index={props.index}>
      <InlineBox>{props.children}</InlineBox>
    </TabPanel>
  );
};

export class AdminView extends Component<Props, State> {
  private readonly organizationBackend = BackendFactory.getOrganizationBackend();

  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: 0,
      loading: true,
    };
  }

  public componentDidMount(): void {
    // let's not block in the componentDidMount
    this.initializeView().then();
  }

  private async initializeView(): Promise<void> {
    const [currentUser, homeOrganization, allRoles] = await Promise.all([
      this.organizationBackend.getCurrentUser(),
      this.organizationBackend.getCurrentHomeOrganization(),
      this.organizationBackend.listRoles(),
    ]);

    this.setState({
      currentUser,
      homeOrganization,
      allRoles,
      selectedOrganization: homeOrganization,
      loading: false,
    });
  }

  private handleOrganizationSelected = (organization: Organization): void => {
    this.setState({
      selectedOrganization: organization,
    });
  };

  private handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    console.log(`Changing page from ${this.state.selectedTab} to ${value}`);
    this.setState({ selectedTab: value });
  };

  private renderOrganizationStructure(): ReactNode {
    if (this.state.selectedOrganization) {
      return <OrganizationManagement organization={this.state.selectedOrganization} />;
    }
  }

  private renderRoles(): ReactNode {
    if (this.state.selectedOrganization) {
      return <Permissions />;
    }
  }

  private renderUsersList(): ReactNode {
    if (this.state.selectedOrganization && this.state.currentUser && this.state.allRoles) {
      return (
        <UserList
          organization={this.state.selectedOrganization}
          currentUser={this.state.currentUser}
          allRoles={this.state.allRoles}
        />
      );
    }
  }

  private renderFormats(): ReactNode {
    if (this.state.selectedOrganization) {
      return <Formats organization={this.state.selectedOrganization} />;
    }
  }

  private renderOrderTemplates(): ReactNode {
    if (this.state.selectedOrganization) {
      return <OrderTemplates organization={this.state.selectedOrganization} />;
    }
  }

  private renderOrganizationSelector(): ReactNode {
    if (this.state.homeOrganization) {
      return (
        <OrganizationSelector
          rootOrganization={this.state.homeOrganization}
          organizationSelected={this.handleOrganizationSelected}
        />
      );
    }
  }

  public render(): ReactNode {
    if (!this.state.loading) {
      return (
        <div style={{ margin: "1rem" }}>
          <Grid container={true} spacing={1} justifyContent={"center"}>
            <Grid item={true} md={12} lg={10} xl={8}>
              {this.renderOrganizationSelector()}
              <Typography variant="h4" style={{ marginTop: "24px" }}>
                {this.state.selectedOrganization?.getName()}
              </Typography>
              <Tabs value={this.state.selectedTab} onChange={this.handlePageChange}>
                <Tab
                  label={translations.admin.texts.users()}
                  icon={<GroupOutlined />}
                  iconPosition="start"
                  data-testid="users-tab"
                />
                <Tab
                  label={translations.admin.texts.organisation()}
                  icon={<AccountTreeOutlined />}
                  iconPosition="start"
                  data-testid="organization-tab"
                />
                <Tab
                  label={translations.admin.texts.formats()}
                  icon={<SpaceDashboardOutlined />}
                  iconPosition="start"
                  data-testid="formats-tab"
                />
                <Tab
                  label={translations.admin.texts.orderTemplates()}
                  icon={<SourceOutlined />}
                  iconPosition="start"
                  data-testid="order-templates-tab"
                />
              </Tabs>
              <TabPage currentPage={this.state.selectedTab} index={0}>
                {this.renderUsersList()}
              </TabPage>
              <TabPage currentPage={this.state.selectedTab} index={1}>
                {this.renderOrganizationStructure()}
              </TabPage>
              <TabPage currentPage={this.state.selectedTab} index={2}>
                {this.renderFormats()}
              </TabPage>
              <TabPage currentPage={this.state.selectedTab} index={3}>
                {this.renderOrderTemplates()}
              </TabPage>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}

export default accessControlled(AdminView, ViewAccessMethods.hasAdminAccess);
