import React, { FunctionComponent, ReactElement } from "react";
import { Typography } from "@mui/material";

interface Props {
  item: { name: string; id: string };
  active?: boolean;
  "data-testid": string;
}

export const AdminCellItem: FunctionComponent<Props> = (props: Props): ReactElement => {
  return (
    <>
      <Typography color="textPrimary" data-testid={props["data-testid"]}>
        {props.active ? <b>{props.item.name}</b> : props.item.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" data-testid={`${props["data-testid"]}-id`}>
        {props.item.id}
      </Typography>
    </>
  );
};
